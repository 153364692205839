import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { makeStyles } from "@material-ui/core/styles";
import { format } from "date-fns";

const useStyles = makeStyles(() => ({
  input: {
    padding: "0px", // Remove padding
    "&::placeholder": {
      color: "rgba(0, 0, 0, 0.54)", // Set placeholder color
      fontFamily: "Inter",
    },
  },
}));

const CustomTextField = ({
  placeholder,
  maxLength,
  type = "text", // Default type to 'text'
  borderColor = "rgba(240, 240, 240, 1)", // Default border color
  backgroundColor = "#F8F8F8", // Default background color
  width,
  name,
  value,
  minDate,
  maxDate,
  ...props
}) => {
  const classes = useStyles();
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <TextField
      name={name}
      value={value}
      placeholder={type === "date" ? "mm/dd/yyyy" : placeholder} // Set placeholder for date type
      InputProps={{
        disableUnderline: true,
        classes: {
          input: classes.input, // Apply custom input class
        },
        style: {
          border: `1px solid ${borderColor}`, // Apply border color from prop
          borderRadius: "10px",
          padding: "14px",
          backgroundColor: backgroundColor, // Apply background color from prop
          color: type === "date" ? "rgba(0, 0, 0, 0.4)" : "inherit", // Placeholder color for date type
          width: "100%",
        },
        endAdornment:
          type === "password" ? (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ) : null,
      }}
      inputProps={{
        maxLength: maxLength,
        min: minDate,
        max: maxDate,
      }}
      type={type === "password" ? (showPassword ? "text" : "password") : type}
      {...props}
      style={{ width: "100%" }}
    />
  );
};

export default CustomTextField;
