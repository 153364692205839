import React from "react";
import { Grid, CssBaseline, makeStyles, Box } from "@material-ui/core";
import RecruiterLoginHeader from "../component/recruiterHeader/RecruiterLoginHeader";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    height: "100vh",
  },
  header: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    zIndex: theme.zIndex.appBar,
    boxShadow: "0px 0px 8px 4px rgba(0, 0, 0, 0.06)",
  },
  mainContainer: {
    display: "flex",
    flexGrow: 1,
    marginTop: "100px", // Adjust this value based on your header height
    zIndex: theme.zIndex.appBar - 10,
    backgroundColor: "rgb(245, 247, 252)"
  },
  sidebar: {
    position: "fixed",
    top: "100px", // Adjust this value based on your header height
    left: 0,
    bottom: 0,
    width: "290px", // Adjust this value based on your sidebar width
    overflowY: "auto",
    flexShrink: 0,
    boxShadow: "10px 0px #0000000D",
    backgroundColor: "#FFFFFF",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    WebkitOverflowScrolling: "touch", // Smooth scrolling on Webkit browsers
    msOverflowStyle: "none", // IE and Edge scrollbar hiding
    scrollbarWidth: "none", // Firefox scrollbar hiding
    zIndex: theme.zIndex.appBar,
  },
  content: {
    flexGrow: 1,
    backgroundColor: "rgb(245, 247, 252)",
    // boxShadow: "0px 0px 8px 4px rgba(0, 0, 0, 0.06)",
    overflowY: "auto",
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
    },
    WebkitOverflowScrolling: "touch", // Smooth scrolling on Webkit browsers
    msOverflowStyle: "none", // IE and Edge scrollbar hiding
    scrollbarWidth: "none", // Firefox scrollbar hiding
  },
}));

function RecruiterInnerLayout({ children }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Box className={classes.header}>
        <RecruiterLoginHeader />
      </Box>
      <Box className={classes.mainContainer}>
        <Box className={classes.content}>{children}</Box>
      </Box>
    </div>
  );
}

export default RecruiterInnerLayout;