export default {
  h1: {
    fontWeight: 800,
    fontSize: 60,
    lineHeight: "81px",
    fontFamily: "Inter",
    "@media(max-width:1610px)": {
      fontSize: 48,
      lineHeight: "70px",
    },
    "@media(max-width:1200px)": {
      fontSize: 46,
      lineHeight: "60px",
    },
    "@media(max-width:1024px)": {
      fontSize: 38,
      lineHeight: "56px",
    },
    "@media(max-width:768px)": {
      fontSize: 34,
      lineHeight: "44px",
    },
    "@media(max-width:490px)": {
      fontSize: 24,
      lineHeight: "28px",
    },
    "@media(max-width:390px)": {
      fontSize: 22,
      lineHeight: "32px",
    },


  },
  h2: {
    fontWeight: 400,
    fontSize: 40,
    lineHeight: "45px",
    fontFamily: "Inter",
    wordBreak: "break-word",
    "@media(max-width:1200px)": {
      fontSize: 35,
      lineHeight: "40px",
    },
    "@media(max-width:992px)": {
      fontSize: 30,
      lineHeight: "35px",
    },
    "@media(max-width:767px)": {
      fontSize: 25,
      lineHeight: "30px",
    },
  },
  h3: {
    color: "#000000CC",
    fontWeight: 600,
    fontFamily: "Inter",
    fontSize: 18,
    lineHeight: "30px",
  
    // Responsive adjustments
    "@media (max-width: 1200px)": {
      fontSize: 18,
      lineHeight: "26px",
    },
    "@media (max-width: 1024px)": {
      fontSize: 16,
      lineHeight: "24px",
    },
    "@media (max-width: 768px)": {
      fontSize: 14,
      lineHeight: "20px",
    },
    "@media (max-width: 390px)": {
      fontSize: 12,
      lineHeight: "16px",
    },
  },
  
  h4: {
    color: "#000000CC",
    fontWeight: 400,
    fontSize: 16,
    lineHeight: "19px",
    fontFamily: "Inter",
    "@media(max-width:1200px)": {
      fontSize: 14,
      lineHeight: "18px",
    },
    "@media(max-width:1024px)": {
      fontSize: 13,
      lineHeight: "16px",
    },
    "@media(max-width:992px)": {
      fontSize: 14,
      lineHeight: "16px",
    },
    "@media(max-width:767px)": {
      fontSize: 12,
    },
  },
  h5: {
    color: "#000000CC",
    fontWeight: 400,
    fontSize: 14,
    lineHeight: "18px", // Increased for better text readability
    fontFamily: "Inter",
    textAlign: "left", // Ensures proper alignment within the container
    margin: 0, // Remove any default margins
    padding: 0, // Prevent unintended padding
    "@media(max-width:1200px)": {
      fontSize: 13,
      lineHeight: "16px", // Adjusted for medium screens
    },
    "@media(max-width:992px)": {
      fontSize: 12,
      lineHeight: "14px", // Adjusted for smaller screens
    },
    "@media(max-width:767px)": {
      fontSize: 12,
      lineHeight: "14px", // Consistent lineHeight for mobile
      textAlign: "center", // Optionally center-align for mobile
    },
  },
  
  h6: {
    fontWeight: 400,
    fontSize: 12,
    lineHeight: "14px",
    color: "#000000CC",
    fontFamily: "'Inter'",
    "@media(max-width:1200px)": {
      fontSize: 12,
      lineHeight: "14px",
    },
    "@media(max-width:992px)": {
      fontSize: 12,
      lineHeight: "12px",
    },
    "@media(max-width:768px)": {
      lineHeight: "10px",
      fontSize: 12,
    },
    "@media(max-width:480px)": {
      lineHeight: "18px",
      fontSize: 12,
    },
  },
  overline: {
    fontWeight: 500,
    fontFamily: "Inter",
  },
  button: {
    textTransform: "capitalize",
    borderRadius: 27,
    fontFamily: "Inter",
  },

  body1: {
    fontWeight: 600,
    lineHeight: "24px",
    fontSize: 20,
    fontFamily: "Inter",
    color: "#000000CC",

    "@media(max-width:1200px)": {
      fontSize: 16,
      lineHeight: "24px",
      color: "#000000CC",

    },
    "@media(max-width:992px)": {
      fontSize: 17,
      lineHeight: "20px",
      color: "#000000CC",
    },
    "@media(max-width:768px)": {
      fontSize: 14,
      lineHeight: "15px",
      color: "#000000CC",
    },
    "@media(max-width:480px)": {
      fontSize: 12,
      lineHeight: "19px",
      color: "#000000CC",
    },
  },
  body2: {
    fontSize: 14,
    fontWeight: 400,
    lineHeight: "22px",
    fontFamily: "Inter",
    color: "#000000CC",
    "@media(max-width:1200px)": {
      fontSize: 12,
      lineHeight: "18px",
      color: "#000000CC",
    },
    "@media(max-width:992px)": {
      fontSize: 13,
      lineHeight: "20px",
      color: "#000000CC",
    },
    "@media(max-width:767px)": {
      fontSize: 12,
      lineHeight: "18px",
      color: "#000000CC",
    },
  },
  body3: {
    fontSize: 13,
    fontWeight: 400,
    lineHeight: "23px",
    fontFamily: "Inter",
    color: "#5E5D5DCC",
    "@media(max-width:1200px)": {
      fontSize: 12,
      lineHeight: "20px",
    },
    "@media(max-width:992px)": {
      fontSize: 11,
      lineHeight: "18px",
    },
    "@media(max-width:767px)": {
      fontSize: 12,
      lineHeight: "18px",
    },
  },
  body4: {
    fontSize: 15,
    fontWeight: "600",
    lineHeight: "31px",
    fontFamily: "Inter",
    color: "#000000CC",
    "@media(max-width:1200px)": {
      fontSize: 12,
      lineHeight: "28px",
    },
    "@media(max-width:992px)": {
      fontSize: 10,
      lineHeight: "24px",
    },
    "@media(max-width:768px)": {
      fontSize: 10,
      lineHeight: "18px",
    },
  },
  body5: {
    fontSize: 30,
    fontWeight: "600",
    lineHeight: "31px",
    fontFamily: "Inter",
    color: "#000000CC",
    "@media(max-width:1200px)": {
      fontSize: 28,
      lineHeight: "28px",
    },
    "@media(max-width:992px)": {
      fontSize: 24,
      lineHeight: "24px",
    },
    "@media(max-width:767px)": {
      fontSize: 20,
      lineHeight: "22px",
    },
  },

  // ............... Project Typography

  cardText: {
    fontWeight: 700,
  },

  headline: {
    fontSize: 24,
    fontWeight: 700,
    lineHeight: "29px",
    fontFamily: "Raleway",
    color: "#161E29",
    "@media(max-width:1200px)": {
      fontSize: 24,
      lineHeight: "28px",
    },
    "@media(max-width:992px)": {
      fontSize: 20,
      lineHeight: "24px",
    },
    "@media(max-width:767px)": {
      fontSize: 18,
      lineHeight: "22px",
    },
  },

  subHeading: {
    fontWeight: 400,
    fontSize: 14,
    lineHeight: "16px",
    color: "rgba(0, 0, 0, 0.8)",
    fontFamily: "'Inter'",
    "@media(max-width:1200px)": {
      fontSize: 14,
      lineHeight: "16px",
    },
    "@media(max-width:992px)": {
      fontSize: 12,
      lineHeight: "14px",
    },
    "@media(max-width:767px)": {
      lineHeight: "12px",
      fontSize: 10,
    },
    "@media(max-width:490px)": {
      lineHeight: "14px",
      fontSize: 12,
    },
  },

  title: {
    fontSize: 16,
    fontWeight: "500",
    lineHeight: "20px",
    fontFamily: "Inter",
    color: "rgba(22, 30, 41, 1)",
    "@media(max-width:1200px)": {
      fontSize: 16,
      lineHeight: "19px",
    },
    "@media(max-width:992px)": {
      fontSize: 14,
      lineHeight: "18px",
    },
    "@media(max-width:767px)": {
      fontSize: 20,
      lineHeight: "18px",
    },
  },

  label: {
    fontSize: 14,
    fontWeight: "400",
    lineHeight: "14px",
    fontFamily: "Inter",
    color: "rgba(22, 30, 41, 1)",
    "@media(max-width:1200px)": {
      fontSize: 14,
      lineHeight: "14px",
    },
    "@media(max-width:992px)": {
      fontSize: 10,
      lineHeight: "12px",
    },
    "@media(max-width:767px)": {
      fontSize: 10,
      lineHeight: "12px",
    },
  },

  modalMessage: {
    fontSize: 20,
    fontWeight: "400",
    marginBottom: "15px",
    lineHeight: "24px",
    fontFamily: "Inter",
    color: "rgba(22, 30, 41, 1)",
    "@media(max-width:1200px)": {
      fontSize: 20,
      lineHeight: "24px",
    },
    "@media(max-width:992px)": {
      fontSize: 18,
      lineHeight: "20px",
    },
    "@media(max-width:767px)": {
      fontSize: 18,
      lineHeight: "20px",
    },
  },

  buttonTypography: {
    // color: "#000000CC",
    fontWeight: 700,
    fontSize: 18,
    // lineHeight: "30px",

    fontFamily: "Inter",
    "@media(max-width:1200px)": {
      fontSize: 18,
      // lineHeight: "26px",
    },
    "@media(max-width:992px)": {
      fontSize: 16,
      // lineHeight: "24px",
    },
    "@media(max-width:768px)": {
      fontSize: 14,
      fontWeight: 600,
      // lineHeight: "20px",

    },
    "@media(max-width:390px)": {
      fontSize: 12,
      fontWeight: 600,
      // lineHeight: "14px",
    },

  },
};
