import React, { Suspense, Fragment, useEffect } from "react";
import { Router, Switch, Route } from "react-router-dom";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { routes } from "./routes";
import { createBrowserHistory } from "history";
import AuthContext from "./context/Auth";
import RecruitAuthContext from "./context/recruiterAuth";
import PageLoading from "./component/PageLoading";
import AuthGuard from "./config/AuthGuard";
import { ThemeProvider } from "@material-ui/core";
import { createMuiTheme } from "./theme";
import { Toaster } from "react-hot-toast";
// import { generateToken, messaging } from "./Notifications/firebase";
import { onMessage } from "firebase/messaging";
import JobContext from "./context/JobContext";
import ProfileProvider from "./context/ProfileContext";
import AuthProvider from "./context/Auth";
import RecruiterGuard from "./config/RecruiterGuard";
// import dotenv from "dotenv";

// dotenv.config();

function App() {
  const theme = createMuiTheme();
  const history = createBrowserHistory();
  // const [token, setToken] = React.useState(null);

  // useEffect(() => {
  //   const fetchToken = async () => {
  //     try {
  //       const tokens = await generateToken();
  //       console.log(tokens, "tokentoken");
  //       setToken(tokens);
  //     } catch (error) {
  //       console.error("Failed to fetch token", error);
  //     }
  //   };

  //   fetchToken();

  //   const unsubscribe = onMessage(messaging, (payload) => {
  //     const { title, body } = payload.notification;
  //     const audio = new Audio('/sound/notification.mp3');
  //     audio.play();
  //     console.log("payload", payload);
  //   });

  //   return () => {
  //     // Clean up the listener on unmount
  //     if (unsubscribe) unsubscribe();
  //   };
  // }, []);

  console.log(theme);

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <AuthContext>
            <RecruitAuthContext>
              <JobContext>
                <ProfileProvider history={history}>
                  <Router history={history}>
                    <AuthProvider>
                      <RenderRoutes data={routes} />
                    </AuthProvider>
                  </Router>
                </ProfileProvider>
              </JobContext>
            </RecruitAuthContext>
          </AuthContext>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </div>
  );
}

function RenderRoutes({ data }) {
  return (
    <Suspense fallback={<PageLoading />}>
      <Switch>
        {data.map((route, i) => {
          const Component = route.component;
          const Guard =
            route.type === "seekar"
              ? AuthGuard
              : route.type === "recruiter"
              ? RecruiterGuard
              : Fragment;
          const Layout = route.layout || Fragment;
          // console.log(Guard);
          return (
            <Route
              exact={route.exact}
              key={i}
              path={route.path}
              render={(props) => (
                <Guard>
                  <Toaster position="top-right" />
                  <Layout>
                    {route.routes ? (
                      <RenderRoutes data={route.routes} />
                    ) : (
                      <Component {...props} />
                    )}
                  </Layout>
                </Guard>
              )}
            />
          );
        })}
      </Switch>
    </Suspense>
  );
}

export default App;
