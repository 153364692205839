import _ from "lodash";
import { colors, createTheme, responsiveFontSizes } from "@material-ui/core";
import typography from "./typography";

const baseOptions = {
  typography,
  overrides: {
    MuiTableCell: {
      root: {
        borderBottom: "transparent",
      },
      head: {
        backgroundColor: "#5E58E4", // Sets header background color to green
        color: "#FFFFFF", // Makes header text white
        fontWeight: "bold", // Bolds the header text
        whiteSpace: "noWrap", // Prevents text wrapping
        padding: "10px", // Consistent padding
      },
    },
    MuiTableRow: {
      root: {},
    },
    MuiFormLabel: {
      root: { color: "#222" },
      colorSecondary: {
        "&.Mui-focused": {
          color: "#222",
        },
      },
    },
    MuiFormControlLabel: {
      root: {
        marginRight: "0px",
      },
    },
    MuiListSubheader: {
      root: {
        color: "#000000",
        fontSize: "22px !important",
        fontWeight: "600 !important",
        lineHeight: "33px !important",
      },
    },
    MuiOutlinedInput: {
      root: {
        background: "white",
        borderRadius: "10px",
        height: "50px",
        //border: "1px solid #AFB0B6",
      },
      adornedStart: {
        paddingLeft: "14px",
        "@media (max-width: 480px)": {
          paddingLeft: "10px",
        },
      },
      notchedOutline: {
        borderColor: "rgba(0, 0, 0, 0.08)",
      },
      colorSecondary: {
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
          color: "white",
          borderColor: "white",
        },
        "&.Mui-focused": {
          color: "white",
        },
      },
      input: {
        padding: "11px 10px",
        "@media (max-width: 480px)": {
          fontSize: "12px",
        },
      },
    },

    MuiPaper: {
      outlined: {
        padding: "20px",
        width: "100%",
      },
      elevation1: {
        background: "#fff",
        // borderRadius: "10px",
        padding: "26px 20px",
        boxShadow: "none",
      },
      elevation2: {
        // background:
        //   "linear-gradient(180deg, rgba(0, 0, 0, 0.03) 0%, rgba(0, 0, 0, 0) 68.15%)",
        border: "none",
        borderRadius: "10px",
        padding: "20px",
        boxShadow: "none",
      },
    },
    MuiPopover: {
      root: {
        zIndex: 99999,
      },
    },
    MuiMenuItem: { root: { paddingLeft: "20px" } },
    MuiListItem: {
      root: {
        alignItems: "self-start",
      },
      gutters: {
        paddingLeft: 0,
      },
    },

    MuiListItemSecondaryAction: {
      root: {
        right: 0,
      },
    },
    MuiDialog: {
      root: {
        padding: "0px 0px",
      },
      paperScrollPaper: {
        Width: 450,
        maxWidth: "100%",
      },
      paper: {
        overflowY: "unset",
        borderRadius: "10px !important",
      },
      paperWidthSm: {
        maxWidth: "900px !important",
      },
    },
    MuiInputBase: {
      input: {
        fontSize: 14,
        fontWeight: "400",
        lineHeight: "21px",
      },
    },
    MuiBackdrop: {
      root: { backgroundColor: "rgba(0, 0, 0, 0.75)" },
    },
    MuiAutocomplete: {
      option: {
        fontFamily: "Inter !important",
        fontSize: "12px !important",
        fontWeight: "400 !important",
        lineHeight: "18px !important",
        letterSpacing: "0px !important",
        textAlign: "left !important",
      },
    },

    MuiListItemIcon: {
      root: {
        minWidth: "0px",
      },
    },

    MuiStepper: {
      root: {
        padding: "0px",
      },
    },
    MuiStepConnector: {
      completed: {
        "& $lineHorizontal": {
          borderColor: "#5E58E4",
        },
      },
    },
    MuiStepLabel: {
      labelContainer: {
        // width: "105%",
      },
    },

    MuiButton: {
      containedSecondary: {
        color: "#fff",
        height: "53px",
        // width: "120px",
        padding: "10px 20px",
        fontSize: "16px",
        border: "1px solid #5E58E4",
        backgroundColor: "#5E58E4",
        boxShadow: "0px 4px 10px 0px #00000033", // Add shadow on hover
        fontWeight: "400",
        lineHeight: "19px",
        fontFamily: "'Inter'",
        borderRadius: "10px",
        boxSizing: "border-box", // Ensure padding and border are included in the button size
        transition: "all 0.3s ease", // Smooth transition for hover effects
        "&:hover": {
          border: "1px solid #5E58E4",
          boxShadow: "none",
          color: "#5E58E4",
          backgroundColor: "#fff",
        },
        "@media (max-width: 600px)": {
          fontSize: "12px",
        },
      },

      containedPrimary: {
        color: "#474747",
        height: "53px",
        width: "120px",
        padding: "10px 20px",
        fontSize: "16px",
        backgroundColor: "#fff",
        fontWeight: "400",
        lineHeight: "19px",
        fontFamily: "Inter",
        borderRadius: "10px",
        border: "1px solid transparent", // Set a transparent border to keep consistent sizing
        boxShadow: " 0px 4px 10px 0px #00000033;",
        transition: "all 0.3s ease", // Smooth transition
        "&:hover": {
          border: "1px solid #5E58E4",
          boxShadow: "none",
          color: "#5E58E4",
          backgroundColor: "#fff",
        },
        "@media (max-width: 600px)": {
          fontSize: "14px",
        },
      },

      contained: {
        color: "#fff",
        height: "40px",
        padding: "10px 39px",
        fontSize: "14px",
        background: "#fff",
        border: "1px solid #4D164F",
        fontWeight: "500",
        lineHeight: "21px",
        fontFamily: "Inter",
        borderRadius: "50px",
        // boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25) !important",
        backgroundColor:
          "linear-gradient(275deg, #4D164F 4.07%, #681E65 98.21%)",
        "&:hover": {
          color: "#fff",
          border: "1px solid #E1E1E1",
          backgroundColor: "#F2F2F2",
        },
      },
      outlinedPrimary: {
        color: "#080515",
        border: "1px solid #4D164F",
        padding: "5px 25px",
        fontWeight: "500",
        borderRadius: "50px",
        fontSize: "13px",
        "&:hover": {
          backgroundColor: "#4D164F",
          border: "1px solid #4D164F",
          color: "#fff",
        },
      },
      outlinedSizeSmall: {
        padding: "6px 23px",
        fontSize: "16px",
        lineHeight: " 24px",
      },
      containedDisabled: {
        color: "#fff !important",
        height: "50px",
        padding: "15px 40px",
        fontSize: "14px",
        background: "linear-gradient(275deg, #4D164F 4.07%, #681E65 98.21%)",
        border: "2px solid #4D164F",
        fontWeight: "500",
        lineHeight: "21px",
        fontFamily: "Outfit",
        borderRadius: "50px",

        "&:hover": {
          color: "#681E65",
          border: "2px solid #681E65",
          background: "#fff !important",
        },
      },
    },
    MuiDrawer: {
      paperAnchorDockedLeft: {
        borderRight: "0",
      },
    },
    MuiMenu: {
      paper: { top: "47px" },
    },

    MuiTypography: {
      subtitle1: {
        color: "#000",
        fontSize: "14px",
        fontWeight: 500,
        lineHeight: " 16px",
        colorSecondary: {
          color: "#8d8989",
        },
      },
    },
  },
};

const themesOptions = {
  typography: {
    fontWeight: 400,
    fontFamily: "Inter",
  },
  palette: {
    type: "light",
    action: {
      primary: "#20509e",
    },
    background: {
      default: "#fff",
      dark: "#f3f7f9",
      paper: colors.common.white,
    },
    primary: {
      main: "#5E58E4",
      dark: "#080515",
      light: "rgba(0, 0, 0, 0.60)",
    },
    secondary: {
      main: "#fff",
    },
    warning: {
      main: "#ffae33",
      dark: "#ffae33",
      light: "#fff1dc",
    },
    success: {
      main: "#54e18c",
      dark: "#54e18c",
      light: "#e2faec",
    },
    error: {
      main: "#FE3737",
      dark: "#FE3737",
      light: "#FE3737",
    },
    text: {
      primary: "#161E29",
      secondary: "#344054",
    },
    common: {
      black: "#222222",
    },
  },
};

export const createMuiTheme = (config = {}) => {
  let theme = createTheme(_.merge({}, baseOptions, themesOptions));

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
};
