import React from "react";
import { Box } from "@material-ui/core";
// import Footer from "../../component/Footer";
import BeforeLoginHeader from "../component/recruiterHeader/BeforeLoginHeader";

function PricingLayout({ children }) {
  return (
    <div>
      <Box >
        <Box>
          <BeforeLoginHeader />
        </Box>
        {children}
        {/*<Box>
                <Footer />
            </Box>*/}
      </Box>
    </div>
  );
}

export default PricingLayout;
