// src/routes.js

import React, { lazy, Suspense } from "react";
import HomeLayout from "./layouts/HomeLayout";
import DashboardLayout from "./layouts/DashboardLayout";
import RecruiterDashboardLayout from "./layouts/RecruiterDashboardLayout";
import JobDetailsLayout from "./layouts/JobDetailsLayout";
import RecruiterInnerLayout from "./layouts/RecruiterInnerLayout";
import ProfileHomeLayout from "./layouts/ProfileHomeLayout";
import RecruiterHomeLayout from "./layouts/recruiterLayouts/RecruiterHomeLayout";
import RecruiterPricingLayout from "./layouts/recruiterLayouts/RecruiterPricingLayout";
import PricingLayout from "./layouts/PricingLayout";
import AuthGuard from "./config/AuthGuard";

export const routes = [
  {
    exact: true,
    path: "/",

    component: lazy(() =>
      import("./views/pages/jobSeeker/landingPage/LandingPage")
    ),
  },
  {
    exact: true,
    path: "/blog",
    layout: HomeLayout,
    component: lazy(() => import("./views/pages/jobSeeker/blogs/Blog")),
  },
  {
    exact: true,
    path: "/blogdetails/:id",
    layout: HomeLayout,
    component: lazy(() => import("./views/pages/jobSeeker/blogs/BlogDetails")),
  },
  {
    exact: true,
    path: "/profile",

    type: "seekar",
    layout: ProfileHomeLayout,
    component: lazy(() =>
      import("./views/pages/jobSeeker/completeProfile/Profile")
    ),
  },
  // {
  //   exact: true,
  //   path: "/updateprofile",
  //   layout: JobDetailsLayout,
  //   component: lazy(() =>
  //     import("./views/pages/jobSeeker/completeProfile/Profile")
  //   ),
  // },
  {
    exact: true,
    path: "/completeprofilewitoutCV",
    layout: ProfileHomeLayout,
    component: lazy(() =>
      import("./views/pages/jobSeeker/completeProfile/CompleteProfileWitoutCV")
    ),
  },
  {
    exact: true,
    path: "/job-search",
    layout: ProfileHomeLayout,
    component: lazy(() => import("./views/pages/jobSeeker/findJobs/JobSearch")),
  },
  // {
  //   exact: true,
  //   path: "/jobsearch",
  //   layout: ProfileHomeLayout,
  //   component: lazy(() => import("./views/pages/jobSeeker/findJobs/JobSearch")),
  // },
  {
    exact: true,
    path: "/jobdetails",
    type: "seekar",
    layout: JobDetailsLayout,
    component: lazy(() =>
      import("./views/pages/jobSeeker/findJobs/JobDetails")
    ),
  },
  {
    exact: true,
    path: "/terms&condition",
    layout: HomeLayout,
    component: lazy(() => import("./views/pages/jobSeeker/TermAndCondition")),
  },
  {
    exact: true,
    path: "/privacy-policy",
    layout: HomeLayout,
    component: lazy(() => import("./views/pages/jobSeeker/PrivacyPolicy")),
  },
  {
    exact: true,
    path: "/faq",
    layout: HomeLayout,
    component: lazy(() => import("./views/pages/jobSeeker/faq")),
  },
  {
    exact: true,
    path: "/aboutus",
    layout: HomeLayout,
    component: lazy(() => import("./views/pages/jobSeeker/AboutUs")),
  },
  {
    exact: true,
    path: "/contactus",
    layout: HomeLayout,
    component: lazy(() => import("./views/pages/jobSeeker/ContactUs")),
  },
  {
    exact: true,
    path: "/dashboard",

    type: "seekar",
    layout: DashboardLayout,
    component: lazy(() =>
      import("./views/pages/jobSeeker/dashboard/Dashboard")
    ),
  },

  {
    exact: true,
    path: "/notification",
    type: "seekar",
    layout: DashboardLayout,
    component: lazy(() =>
      import("./views/pages/jobSeeker/dashboard/Notification")
    ),
  },
  {
    exact: true,
    path: "/myprofile",
    layout: DashboardLayout,
    type: "seekar",
    component: lazy(() =>
      import("./views/pages/jobSeeker/dashboard/MyProfile")
    ),
  },
  {
    exact: true,
    path: "/myresume",
    layout: DashboardLayout,
    component: lazy(() => import("./views/pages/jobSeeker/dashboard/MyResume")),
  },
  {
    exact: true,
    path: "/helpandcomplaint",
    layout: DashboardLayout,
    component: lazy(() =>
      import("./views/pages/jobSeeker/dashboard/HelpAndComplaint")
    ),
  },
  {
    exact: true,
    path: "/appliedjobs",
    type: "seekar",
    layout: DashboardLayout,
    component: lazy(() =>
      import("./views/pages/jobSeeker/dashboard/AppliedJobs")
    ),
  },
  {
    exact: true,
    path: "/whitelistedJobs",
    type: "seekar",
    layout: DashboardLayout,
    component: lazy(() =>
      import("./views/pages/jobSeeker/dashboard/WhitelistedJobs")
    ),
  },
  {
    exact: true,
    path: "/message",
    layout: DashboardLayout,
    component: lazy(() =>
      import("./views/pages/jobSeeker/dashboard/MessageScreen")
    ),
  },
  {
    exact: true,
    path: "/setting",
    layout: DashboardLayout,
    component: lazy(() =>
      import("./views/pages/jobSeeker/dashboard/settings/Setting")
    ),
  },
  {
    exact: true,
    path: "/jobpreferencesetup",
    layout: DashboardLayout,
    component: lazy(() =>
      import("./views/pages/jobSeeker/dashboard/settings/JobPreferenceSetup")
    ),
  },
  {
    exact: true,
    path: "/profileanalytics",
    layout: DashboardLayout,
    component: lazy(() =>
      import(
        "./views/pages/jobSeeker/dashboard/profileAnalytics/ProfileAnalytics"
      )
    ),
  },

  // Job Recruiter Routes

  {
    exact: true,
    path: "/recruiterNotification",
    layout: RecruiterDashboardLayout,
    component: lazy(() =>
      import("./views/pages/recruiter/dashboard/RecruiterNotification")
    ),
  },

  {
    exact: true,
    path: "/recruiterlandingpage",
    component: lazy(() =>
      import("./views/pages/recruiter/landingPage/RecruiterLandingPage")
    ),
  },
  {
    exact: true,
    path: "/Companyregistration",
    component: lazy(() =>
      import("./views/pages/recruiter/recruiterAuth/CompanyRegistration")
    ),
  },
  {
    exact: true,
    path: "/pricing",
    layout: PricingLayout,
    component: lazy(() => import("./views/pages/recruiter/pricing/Pricing")),
  },
  {
    exact: true,
    path: "/pricings",
    layout: RecruiterPricingLayout,
    component: lazy(() => import("./views/pages/recruiter/pricing/Pricing")),
  },
  {
    exact: true,
    path: "/recruiter-blogs",

   
    layout: RecruiterHomeLayout,
    component: lazy(() =>
      import("./views/pages/recruiter/recruiterBlogs/RecruiterBlog")
    ),
  },
  {
    exact: true,
    path: "/recruiter-blogdetails",
    layout: RecruiterHomeLayout,
    component: lazy(() =>
      import("./views/pages/recruiter/recruiterBlogs/RecruiterBlogDetails")
    ),
  },
  {
    exact: true,
    path: "/contact-us",
    layout: RecruiterHomeLayout,
    component: lazy(() => import("./views/pages/jobSeeker/ContactUs")),
  },
  {
    exact: true,
    path: "/termsandcondition",
    layout: RecruiterHomeLayout,
    component: lazy(() => import("./views/pages/jobSeeker/TermAndCondition")),
  },
  {
    exact: true,
    path: "/privacyPolicy",
    layout: RecruiterHomeLayout,
    component: lazy(() => import("./views/pages/jobSeeker/PrivacyPolicy")),
  },
  {
    exact: true,
    path: "/faqs",
    layout: RecruiterHomeLayout,
    component: lazy(() => import("./views/pages/jobSeeker/faq")),
  },
  {
    exact: true,
    path: "/recruiterDashboard",

    type: "recruiter",
    layout: RecruiterDashboardLayout,
    component: lazy(() =>
      import("./views/pages/recruiter/dashboard/RecruiterDashboard")
    ),
  },
  {
    exact: true,
    path: "/subscription",
    type: "recruiter",

    layout: RecruiterDashboardLayout,
    component: lazy(() =>
      import("./views/pages/recruiter/subscription/SubscriptionDetail")
    ),
  },

  {
    exact: true,
    path: "/postNewJob",
    type: "recruiter",

    layout: RecruiterDashboardLayout,
    component: lazy(() =>
      import("./views/pages/recruiter/postNewJob/PostNewJob")
    ),
  },
  {
    exact: true,
    path: "/support",
    type: "recruiter",

    layout: RecruiterDashboardLayout,
    component: lazy(() => import("./views/pages/recruiter/support/Support")),
  },

  {
    exact: true,
    path: "/allApplicants/viewApplicantDetails",
    type: "recruiter",

    layout: RecruiterDashboardLayout,
    component: lazy(() =>
      import("./views/pages/recruiter/Applicants/ViewApplicantDetails")
    ),
  },

  {
    exact: true,
    path: "/ManageJob",
    type: "recruiter",

    layout: RecruiterDashboardLayout,
    component: lazy(() =>
      import("./views/pages/recruiter/manageJob/ManageJob")
    ),
  },

  {
    exact: true,
    path: "/ManageJob/editJobPost",
    type: "recruiter",

    layout: RecruiterDashboardLayout,
    component: lazy(() =>
      import("./views/pages/recruiter/manageJob/EditJobPost")
    ),
  },

  {
    exact: true,
    path: "/ManageJob/viewJob",
    type: "recruiter",

    layout: RecruiterInnerLayout,
    component: lazy(() => import("./views/pages/recruiter/manageJob/ViewJob")),
  },

  {
    exact: true,
    path: "/CompanyProfile",
    type: "recruiter",

    layout: RecruiterDashboardLayout,
    component: lazy(() =>
      import("./views/pages/recruiter/companyProfile/CompanyProfile")
    ),
  },
  {
    exact: true,
    path: "/CompanyProfile/editProfile",
    type: "recruiter",

    layout: RecruiterInnerLayout,
    component: lazy(() =>
      import("./views/pages/recruiter/companyProfile/EditProfile")
    ),
  },
  {
    exact: true,
    path: "/chats",
    type: "recruiter",

    layout: RecruiterDashboardLayout,
    component: lazy(() => import("./views/pages/recruiter/chat/Chat")),
  },
  //Applicants module
  {
    exact: true,
    path: "/allApplicants",
    type: "recruiter",

    layout: RecruiterDashboardLayout,
    component: lazy(() =>
      import("./views/pages/recruiter/Applicants/AllApplicants")
    ),
  },
  {
    exact: true,
    path: "/shortlisted-resume",
    type: "recruiter",

    layout: RecruiterDashboardLayout,
    component: lazy(() =>
      import("./views/pages/recruiter/shortlistedResume/ShortlistedResume")
    ),
  },
  {
    exact: true,
    path: "/shortlisted-resume/viewApplicantDetails",
    type: "recruiter",

    layout: RecruiterDashboardLayout,
    component: lazy(() =>
      import("./views/pages/recruiter/shortlistedResume/ViewShortlistedResume")
    ),
  },
  // {/allApplicants/viewApplicantDetails
  //   exact: true,
  //   path: "/viewApplicantDetails",
  //   layout: RecruiterDashboardLayout,
  //   component: lazy(() => import("./views/pages/recruiter/Applicants/ViewApplicantDetails")),
  // },
];
