import React, { useState } from "react";
import {
  CssBaseline,
  Box,
  Drawer,
  useMediaQuery,
  makeStyles,
} from "@material-ui/core";
import Sidebar from "../component/sidebar/Sidebar";
import AfterLoginHeader from "../component/header/AfterLoginheader";

const useStyles = makeStyles((theme) => ({
  layoutContainer: {
    display: "flex",
    flexDirection: "column",
    height: "100vh",
    backgroundColor: "#F2F4F7",
    overflow: "hidden",
  },
  header: {
    flexShrink: 0,
  },
  mainContent: {
    display: "flex",
    flexGrow: 1,
    height: "calc(100vh - 100px)", // Adjusting the height to account for header (change 64px based on your header height)
    overflow: "hidden", // Prevent overflow

  },
  sidebar: {
    width: 300, // Increased the width from 240 to 300
    flexShrink: 0,
    backgroundColor: "#FFFFFF",
    boxShadow: "5px 0px #0000000D",
    overflowY: "auto", // Enable vertical scrolling in the sidebar
    height: "100%", // Ensures the sidebar takes full height of the remaining screen
    [theme.breakpoints.down("sm")]: {
      display: "none", // Hide sidebar on small screens
    },


    scrollbarWidth: "none", // Hide scrollbar for Firefox
    "&::-webkit-scrollbar": {
      display: "none", // Hide scrollbar for Chrome, Safari, Opera
    },
  },
  content: {
    flex: 1,
    overflowY: "auto",
    padding: theme.spacing(2.5),
    backgroundColor: "#F5F7FC",
    height: "100%", // Ensures content section takes remaining height
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  drawerPaper: {
    width: 300, // Increased the drawer width to match the sidebar width
  },
}));

function DashboardLayout({ children }) {
  const classes = useStyles();
  const isSmallScreen = useMediaQuery("(max-width:960px)");
  const [isDrawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = () => {
    setDrawerOpen(!isDrawerOpen);
  };

  return (
    <div className={classes.layoutContainer}>
      <CssBaseline />
      {/* Header */}
      <Box className={classes.header}>
        <AfterLoginHeader toggleSidebar={toggleDrawer} />
      </Box>

      <div className={classes.mainContent}>
        {/* Sidebar */}
        {isSmallScreen ? (
          <Drawer
            open={isDrawerOpen}
            onClose={toggleDrawer}
            classes={{ paper: classes.drawerPaper }}
            variant="temporary"
            anchor="left"
          >
            <Sidebar />
          </Drawer>
        ) : (
          <Box className={classes.sidebar}>
            <Sidebar />
          </Box>
        )}

        {/* Main Content */}
        <Box className={classes.content}>{children}</Box>
      </div>
    </div>
  );
}

export default DashboardLayout;
