import axios from "axios";
import ApiConfig from "./apiconfig";
import toast from "react-hot-toast";

const Token = sessionStorage.getItem("token");

const user = sessionStorage.getItem("user");
let token = Token;
if (user) {
  try {
    const parsedUser = JSON.parse(user);
    token = parsedUser?.token || null; // Safely access the token
  } catch (error) {
    console.error("Error parsing user from sessionStorage:", error);
  }
} else {
  console.warn("No user found in sessionStorage");
}

// console.log("Koca: token in the sercvie file ", token);
export const postApiHandler = async (endPoint, body) => {
  try {
    const res = await axios({
      method: "POST",
      url: ApiConfig[endPoint],
      data: body,
    });
    if (res?.data?.responseCode === 200 || res.data?.responseCode === 201) {
      return res.data;
    } else {
      console.log("*******************");
      return res.data;
    }
  } catch (error) {
    // console.log("*******************", error?.response?.data);
    return error?.response?.data;
  }
};

export const postApiHandlerWithToken = async (endPoint, body) => {
  // console.log(token);
  try {
    const res = await axios({
      method: "POST",
      url: ApiConfig[endPoint],
      data: body,
      headers: {
        token: sessionStorage.getItem("token"),
      },
    });
    if (res?.data?.responseCode === 200 || res.data?.responseCode === 201) {
      return res.data;
    } else {
      return res.data;
    }
  } catch (error) {
    // console.log(error?.response?.data, "postApiHandlerWithToken");
    return error?.response?.data;
  }
};

export const postAIApiHandlerWithToken = async (endPoint, body) => {
  try {
    const res = await axios({
      method: "POST",
      url: ApiConfig[endPoint],
      data: body,
      headers: {
        token: sessionStorage.getItem("token"),
        "Content-Type": "multipart/form-data",
      },
    });
    if (res?.data?.responseCode === 200 || res.data?.responseCode === 201) {
      return res.data;
    } else {
      return res.data;
    }
  } catch (error) {
    // console.log(error?.response?.data, "postAIApiHandlerWithToken");
    return error?.response?.data;
  }
};
export const getApiHandlerWithToken = async (endPoint, params) => {
  const user = sessionStorage.getItem("user");
  let token = Token;
  // console.log(Token);
  if (user) {
    try {
      const parsedUser = JSON.parse(user);
      token = parsedUser?.token || null;
    } catch (error) {
      console.error("Error parsing user from sessionStorage:", error);
    }
  }

  if (!token) {
    console.error("No token found! Please provide a token.");
    return; // Exit the function if no token is found
  }

  // console.log("Token being used for the API call:", token);

  try {
    const res = await axios({
      method: "GET",
      url: ApiConfig[endPoint],
      params: params,
      headers: {
        token: sessionStorage.getItem("token"),
      },
    });

    if (res.data?.responseCode === 200 || res.data?.responseCode === 201) {
      return res.data;
    }
  } catch (error) {
    // console.log(`Error while calling API ${endPoint}:`, error);
    return error?.response?.data;
  }
};

export const getDataHandlerWithToken = async (endPoint, query, data) => {
  try {
    const res = await axios({
      method: "GET",
      url: ApiConfig[endPoint],
      headers: {
        token: sessionStorage.getItem("token"),
      },
      params: query,
      data: data,
    });
    if (res.data?.responseCode == 200 || res.data?.responseCode === 201) {
      return res.data;
    }
  } catch (error) {
    console.log(`Error while api ${endPoint} is calling`, error);
    return error?.response?.data;
  }
};
export const getApiHandler = async (endPoint, params) => {
  // alert("api")
  try {
    const res = await axios({
      method: "GET",
      url: ApiConfig[endPoint],
      params: params,
    });
    if (res?.data?.responseCode === 200 || res.data?.responseCode === 201) {
      return res.data;
    }
  } catch (error) {
    console.log(error?.response?.data?.responseMessage, "errorerror");
    // !(error?.response?.data?.responseMessage === "No Contact us data found") &&
    //   toast.error(
    //     error?.response?.data?.responseMessage || "Something went wrong"
    //   );
    return null;
  }
};
export const putApiHandlerWithToken = async (endPoint, params) => {
  try {
    const res = await axios({
      method: "PUT",
      url: ApiConfig[endPoint],
      params: params,
      headers: {
        token: token,
      },
    });
    if (res.data?.responseCode == 200 || res.data?.responseCode === 201) {
      return res.data;
    }
  } catch (error) {
    console.log(`Error while api ${endPoint} is calling`, error);
    return error?.response?.data;
  }
};

export const putDataHandlerWithToken = async (endPoint, body) => {
  try {
    const res = await axios({
      method: "PUT",
      url: ApiConfig[endPoint],
      data: body,
      headers: {
        token: Token,
      },
    });

    if (res.data?.responseCode == 200 || res.data?.responseCode === 201) {
      return res.data;
    }
  } catch (error) {
    console.log(`Error while api ${endPoint} is calling`, error);
    return error?.response?.data;
  }
};

export const deleteApiHandlerWithToken = async (endPoint, body) => {
  try {
    const res = await axios({
      method: "DELETE",
      url: ApiConfig[endPoint],
      data: body,
      headers: {
        token: token,
      },
    });
    if (res.data?.responseCode == 200 || res.data?.responseCode === 201) {
      return res.data;
    }
  } catch (error) {
    console.log(`Error while api ${endPoint} is calling`, error);
    return error?.response?.data;
  }
};
export const deleteDataHandlerWithToken = async (endPoint, query) => {
  try {
    const res = await axios({
      method: "DELETE",
      url: ApiConfig[endPoint],
      headers: {
        token: sessionStorage.getItem("token"),
      },
      params: query,
    });
    if (res.data.responseCode === 200) {
      return res.data;
    } else {
      return res;
    }
  } catch (error) {
    console.log(error);
  }
};

export const postApiSocialHandler = async (endPoint, body) => {
  try {
    const res = await axios({
      method: "POST",
      url: ApiConfig[endPoint],
      data: body,
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (res?.data?.responseCode === 200 || res.data?.responseCode === 201) {
      return res.data;
    }
  } catch (error) {
    console.log(error?.response?.data, "333333333333333333333333");
    return error?.response?.data;
  }
};

export const getDataHandler = async (endPoint, query, data) => {
  try {
    const res = await axios({
      method: "GET",
      url: ApiConfig[endPoint],

      params: query,
      data: data,
    });

    if (res?.data?.responseCode === 200) {
      return res.data;
    } else {
      toast.error(res?.data?.message);
      return res;
    }
  } catch (error) {
    if (error.response) {
      return error.response;
    } else {
      return error;
    }
  }
};

export const postDataHandlerWithToken = async (endPoint, data) => {
  try {
    const res = await axios({
      method: "POST",
      url: ApiConfig[endPoint],
      headers: {
        token: sessionStorage.getItem("token"),
      },
      data: data,
    });
    if (res?.data?.responseCode === 200) {
      return res.data;
    } else {
      return res;
    }
  } catch (error) {
    return error.response;
  }
};

export const putDataHandler = async (endPoint, data) => {
  try {
    const res = await axios({
      method: "POST",
      url: ApiConfig[endPoint],
      data: data,
    });
    if (res.data.responseCode === 200) {
      return res.data;
    } else {
      return res;
    }
  } catch (error) {
    console.log(error);
    if (error.response) {
      return error.response;
    }
  }
};

export const postDataHandler = async (endPoint, data) => {
  try {
    const res = await axios({
      method: "POST",
      url: ApiConfig[endPoint],
      data: data,
    });

    if (res?.data?.responseCode === 200) {
      return res.data;
    } else {
      toast.error(res?.data?.message);
      return res;
    }
  } catch (error) {
    if (error.response) {
      return error.response;
    } else {
      return error;
    }
  }
};
export const putDataHandlerWithTokenR = async (endPoint, data, params) => {
  try {
    const res = await axios({
      method: "PUT",
      url: ApiConfig[endPoint],
      headers: {
        token: sessionStorage.getItem("token"),
      },
      data: data,
      params: params,
    });
    if (res.data.responseCode === 200) {
      return res.data;
    } else {
      return res;
    }
  } catch (error) {
    console.log(error);
    if (error.response) {
      return error.response;
    }
  }
};
