import React, { useContext, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Avatar,
  List,
  Box,
  Button,
  ListItem,
  Dialog,
  DialogContent,
  ListItemIcon,
  ListItemText,
  Typography,
  LinearProgress,
  CircularProgress,
} from "@material-ui/core";
import {
  Dashboard as DashboardIcon,
  Person as PersonIcon,
  Description as DescriptionIcon,
  Work as WorkIcon,
  Message as MessageIcon,
  Bookmark as BookmarkIcon,
  Settings as SettingsIcon,
  Help as HelpIcon,
  ExitToApp as ExitToAppIcon,
} from "@material-ui/icons";
import TimelineIcon from "@material-ui/icons/Timeline";
import { useHistory, useLocation } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import ResponsiveLogoutDialog from "../modals/LogoutModal";
import SeekerLogout from "../modals/SeekerLogout";
import { getApiHandlerWithToken } from "../../config/service";
import { ProfileContext } from "../../context/ProfileContext";

const useStyles = makeStyles((theme) => ({
  sidebar: {
    backgroundColor: "#FFFFFF",
    boxShadow: "5px 0px 10px rgba(0, 0, 0, 0.1)", // Add box shadow/elevation for sidebar
  },
  profile: {
    display: "flex",
    gap: "0px",
    backgroundImage:
      "linear-gradient(rgba(94, 88, 228, 0.7), rgba(94, 88, 228, 0.7)),url(images/profilebackground.png)",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    alignItems: "center",
    flexDirection: "column",
    paddingTop: "15px",

    position: "sticky",
    top: 0, // This keeps the profile fixed at the top
    zIndex: 10, // Ensure it stays above the list
    padding: theme.spacing(2),
    backgroundColor: "#fff", // Add background to avoid overlap with list items
  },
  avatar: {
    border: " 1px solid #FFFFFF",
    width: "50px",
    height: "50px",
    objectFit: "contain",
    marginBottom: theme.spacing(1),
  },
  contentBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    gap: "5px",
  },
  role: {
    fontWeight: "700",
    lineHeight: "21px",
    color: "#FFFFFF",
  },
  role1: {
    fontWeight: "500",
    color: "#FFFFFF",
  },
  status: {
    width: "100%",
    paddingLeft: "15%",
    paddingRight: "15%",
    color: "#FFFFFF",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    gap: "5px",
    marginBottom: theme.spacing(2),
    marginTop: "10px",
  },
  listItem: {
    display: "flex",
    alignItems: "center",

    gap: "20px",
    padding: "20px",
    paddingLeft: "30px",
    transition: "background-color 0.3s",
    transition: "background-color 0.3s, color 0.3s", // Add color transition
    "&:hover": {
      backgroundColor: "#5E58E41A", // Light green background on hover
      color: "#5E58E4", // Text color green on hover
      "& $listItemIcon, & $listItemText": {
        color: "#5E58E4", // Ensure both text and icon are green on hover
      },
    },
  },

  listItemText: {
    fontSize: 16,
    fontWeight: "400",
    lineHeight: "19px",
    fontFamily: "Inter",
    color: "#1E252E",
    "&:hover": {
      color: "#5E58E4",
    },
  },
  success: {
    fontWeight: 700,
    fontSize: 18,
    lineHeight: "21px",
    fontFamily: "Inter",
    color: "#FFFFFF",
    marginLeft: theme.spacing(1),
  },
  selected: {
    backgroundColor: "#5E58E41A",
    display: "flex",
    alignItems: "center",

    gap: "20px",
    padding: "20px",
    paddingLeft: "30px",
    "& $listItemText": {
      color: "#5E58E4",
      fontWeight: "500",
    },
    "& $listItemIcon": {
      color: "#5E58E4",
    },
  },
  mainBox: {
    width: "460px",
    height: "349px",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  subBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "15px",
    gap: "25px",
  },
  image: {
    width: "63px",
    height: "67px",
  },
  dialogheading: {
    fontSize: "28px",
    fontWeight: "600",
    color: "#161E29",
  },
  dialogsubheading: {
    lineHeight: "36px",
    fontSize: "28px",
    fontWeight: "600",
    color: "#0C111D",
  },
  dialogsubheading1: {
    lineHeight: "22px",
    fontSize: "16px",
    fontWeight: "400",
    color: "##344054",
  },
  btnBox: {
    display: "flex",
    gap: "20px",
  },
  btn1: {
    marginTop: "10px",
    width: "200px",
    height: "52px",
    lineHeight: "20px",
    fontSize: "16px",
    fontWeight: "600",
    borderRadius: "8px",
    Padding: "8px, 16px, 8px, 16px",
  },
  btn2: {
    lineHeight: "20px",
    fontSize: "16px",
    fontWeight: "600",
    marginTop: "10px",
    width: "200px",
    height: "52px",
    border: "1px solid #797979",
    backgroundColor: "#797979",
    borderRadius: "8px",
    Padding: "8px, 16px, 8px, 16px",
    color: "#fff",
    "&:hover": {
      border: "1px solid #797979",
      boxShadow: "none",
      color: "#797979",
      backgroundColor: "#fff",
    },
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[900],
  },
  list: {
    padding: 0,
    "& .MuiList-padding": {
      padding: 0,
    },
  },
  progressBar: {
    height: "10px",
    width: "100%",
    borderRadius: "10px",

    border: "1px solid white",
    "& .MuiLinearProgress-bar": {
      backgroundColor: "White", // For the filled portion
    },
    "& .MuiLinearProgress-colorPrimary": {
      backgroundColor: "White", // For the filled portion (alternative way)
    },
    "& .MuiLinearProgress-root": {
      borderRadius: "5px",
      height: "100%",
    },
  },
  progressBarBox: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    borderRadius: "none",
  },
  listItemIcon: {
    height: "20px",
    width: "20px",
    objectFit: "contain",
    minWidth: 20,
    "&:hover": {
      color: "#5E58E4",
    },
  },

  iconImage: {
    width: 20,
    height: 20,
    objectFit: "contain",
  },
}));

const Sidebar = () => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const { avatarSrc, setAvatarSrc, profile, getProfile, loading } =
    useContext(ProfileContext);

  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [open, setOpen] = useState(false);
  const userData = sessionStorage.getItem("user");
  const userObject = userData ? JSON.parse(userData) : null;
  const userId = userObject?.id;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleClick = () => {
    history.push("/profile");
  };
  const handleLogOut = () => {
    history.push("/");
  };

  const handleItemClick = (path) => {
    history.push(path);
  };

  const [logoutModalOpen, setLogoutModalOpen] = useState(false); // State to manage DeleteJobModal open/close

  const handleLogout = () => {
    setLogoutModalOpen(true); // Open DeleteJobModal when "Cancel Plan" is clicked
  };

  const handleLogoutModalClose = () => {
    setLogoutModalOpen(false); // Close DeleteJobModal
  };

  // const savedStep = parseInt(localStorage.getItem("activeStep"), 10) || 0;
  // const totalSteps = 6;
  // const completionPercentage = (savedStep / totalSteps) * 100;

  const truncateText = (text, limit = 30) => {
    return text && text.length > limit ? text.slice(0, limit) + "..." : text;
  };
  const truncateName = (text, limit = 20) => {
    return text && text.length > limit ? text.slice(0, limit) + "..." : text;
  };
  // const getProfile = async () => {
  //   try {
  //     const res = await getApiHandlerWithToken("getProfile", { userId });
  //     if (res?.responseCode === 200) {
  //       setProfile(res?.result);
  //     } else {
  //       console.log("Something went wrong");
  //     }
  //   } catch (error) {
  //     console.error("Error while getting profile", error);
  //   } finally {
  //     setLoading(false); // Set loading to false after data is fetched
  //   }
  // };

  useEffect(() => {
    getProfile();
  }, []);

  useEffect(() => {
    setAvatarSrc(profile?.profilePicture);
  }, [profile]);

  return (
    <div className={classes.sidebar}>
      <div className={classes.profile}>
        {loading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="17vh"
          >
            <CircularProgress size={30} style={{ color: "#fff" }} />
          </Box>
        ) : (
          <>
            <Avatar className={classes.avatar} src={avatarSrc} alt="profile" />
            <Box className={classes.contentBox}>
              <Typography variant="h3" className={classes.role}>
                {truncateName(
                  `${profile?.firstName || ""} ${
                    profile?.lastName || ""
                  }`.trim() || "--"
                )}
              </Typography>
              <Typography variant="h6" className={classes.role1}>
                {truncateText(profile?.experience?.[0]?.jobTitle)}
              </Typography>
              <Typography variant="h6" className={classes.role1}>
                {truncateText(profile?.experience?.[0]?.companyName)}
              </Typography>
            </Box>
            <Typography variant="h6" className={classes.status}>
              {profile?.isComplete > 0 ? (
                <Box className={classes.progressBarBox}>
                  <Box className={classes.progressBar}>
                    <LinearProgress
                      variant="determinate"
                      value={Math.min(profile?.isComplete, 100)}
                      style={{
                        width: `${Math.min(profile?.isComplete, 100)}%`,
                      }}
                    />
                  </Box>

                  <span className={classes.success}>
                    {Math.min(profile?.isComplete, 100)}%{" "}
                  </span>
                </Box>
              ) : (
                <Button
                  onClick={handleClick}
                  style={{
                    padding: "13px 20px 13px 20px",
                    borderRadius: "10px",
                    background: "white",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)", // Add box shadow
                  }}
                >
                  <Typography
                    style={{
                      fontFamily: "Inter",
                      fontSize: "12px",
                      fontWeight: 500,
                      lineHeight: "12.1px",
                      textAlign: "center", // Center text inside Typography (optional here)
                    }}
                  >
                    Update Profile
                  </Typography>
                </Button>
              )}
              {/* <Typography
            variant="h6"
            style={{ fontSize: "10px", lineHeight: "12px", color: "#FFFFFF" }}
          >
            Last Updated Yesterday
          </Typography> */}
            </Typography>
          </>
        )}
      </div>

      <List className={classes.list}>
        {[
          {
            text: "Dashboard",
            imgSrc: "images/dashboardicon.png",
            imgHover: "images/dashboardgreen.png",
            imgActive: "images/dashboardgreen.png",
            path: "/dashboard",
          },
          {
            text: "My Profile",
            imgSrc: "images/myprofileicon.png",
            imgHover: "images/myprofilegreen.png",
            imgActive: "images/myprofilegreen.png",
            path: "/myprofile",
          },
          {
            text: "My Resume",
            imgSrc: "images/myresume.png",
            imgHover: "images/myresumegreen.png",
            imgActive: "images/myresumegreen.png",
            path: "/myresume",
          },
          {
            text: "Applied Jobs",
            imgSrc: "images/appliedjobsidebar.png",
            imgHover: "images/appliedjosgreen.png",
            imgActive: "images/appliedjosgreen.png",
            path: "/appliedjobs",
          },
          {
            text: "Messages",
            imgSrc: "images/messageiconsidebar.png",
            imgHover: "images/messagegreen.png",
            imgActive: "images/messagegreen.png",
            path: "/message",
          },
          {
            text: "Saved jobs",
            imgSrc: "images/saved.png",
            imgHover: "images/savedjobsgreen.png",
            imgActive: "images/savedjobsgreen.png",
            path: "/whitelistedJobs",
          },
          {
            text: "Settings",
            imgSrc: "images/setting.png",
            imgHover: "images/settingscreen.png",
            imgActive: "images/settingscreen.png",
            path: "/setting",
          },
          {
            text: "Help & Complaint",
            imgSrc: "images/helpandcomplain.png",
            imgHover: "images/helpandcomplaingreen.png",
            imgActive: "images/helpandcomplaingreen.png",
            path: "/helpandcomplaint",
          },
          {
            text: "Analytics",
            imgSrc: "images/analytics.png",
            imgHover: "images/analyticsgreen.png",
            imgActive: "images/analyticsgreen.png",
            path: "/profileanalytics",
          },
          {
            text: "Logout",
            imgSrc: "images/logoutsidebar.png",
            imgHover: "images/logoutgreen.png",
            imgActive: "images/logoutgreen.png",
            onClick: handleLogout,
          },
        ].map((item, index) => (
          <ListItem
            button
            key={index}
            className={
              location.pathname === item.path
                ? classes.selected
                : classes.listItem
            }
            onClick={() =>
              item.path
                ? handleItemClick(item.path)
                : item.onClick && item.onClick()
            }
            onMouseEnter={() => setHoveredIndex(index)} // Set hover index on mouse enter
            onMouseLeave={() => setHoveredIndex(null)} // Reset hover index on mouse leave
          >
            <ListItemIcon className={classes.listItemIcon}>
              <img
                src={
                  location.pathname === item.path
                    ? item.imgActive
                    : hoveredIndex === index
                    ? item.imgHover
                    : item.imgSrc
                }
                alt={item.text}
                style={{
                  width: "24px",
                  height: "24px",
                  transition: "transform 0.3s ease, filter 0.3s ease",
                  transform: hoveredIndex === index ? "scale(1.1)" : "scale(1)", // Slight zoom effect on hover
                  filter:
                    hoveredIndex === index || location.pathname === item.path
                      ? "brightness(0) saturate(100%) invert(36%) sepia(55%) saturate(3941%) hue-rotate(239deg) brightness(90%) contrast(94%)" // Exact #5E58E4 color
                      : "none",
                }}
              />
            </ListItemIcon>
            <ListItemText classes={{ primary: classes.listItemText }}>
              {item.text}
            </ListItemText>
          </ListItem>
        ))}
      </List>
      <SeekerLogout open={logoutModalOpen} onClose={handleLogoutModalClose} />
    </div>
  );
};

export default Sidebar;
