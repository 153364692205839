import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Box,
  Divider,
  Grid,
  Typography,
  makeStyles,
  Button,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { getApiHandler } from "../config/service";
import { ProfileContext } from "../context/ProfileContext";
import { JobContext } from "../context/JobContext";
import NoDataFound from "./NoDataFound";

const useStyles = makeStyles((theme) => ({
  megaMenu: {
    backgroundColor: "#fff",
    zIndex: 1000,
    width: "100%",
    display: "none",
    position: "absolute",
    boxShadow: " 0px 22px 23px 0px #0000001A",

    // "@media(max-width:1440px)": {
    //     left: -325,
    //     width: '143%',
    //     paddingLeft: '8.5%',
    // },
    // "@media(max-width:1024px)": {
    //     left: -252,
    //     width: '129.7%',
    //     paddingLeft: '4%',
    // },
    // [theme.breakpoints.down('sm')]: {
    //     position: 'relative',
    //     width: '100%',
    // },
  },
  megaMenuVisible: {
    display: "block",
  },
  megaMenuItem: {
    cursor: "pointer",
    display: "flex",
    gap: "8px",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "flex-start",
      gap: "10px",
    },
  },

  link: {
    textDecoration: "none",
    display: "block",
    "&:hover": {
      backgroundColor: "#e0e0e0",
      border: "unset",
    },
  },
  link1: {
    textDecoration: "none",

    "&:hover": {},
  },
  textItem: {
    backgroundColor: "#F3FBFF",
    padding: "10px",
    borderRadius: "5px",
    "&:hover": {
      padding: "10px",
      boxShadow: "none",
      color: "#fff",
      backgroundColor: "#5E58E4",
    },
  },
  jobbyCategory: {
    display: "flex",
    gap: "30px",
  },
  gridContainer: {
    display: "flex",
    flexDirection: "column",
    marginTop: theme.spacing(1),
  },
  gridItem: {
    padding: theme.spacing(0.8),
    paddingLeft: "0px",
    position: "relative", // Make the container a positioning context for the pseudo-element
    overflow: "hidden", // Hide the overflow to ensure the background doesn't extend outside the item
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
    "&::after": {
      content: '""',
      position: "absolute",
      top: 0,
      width: "0%",
      height: "100%",
      backgroundColor: "#F4F4F4",
      transition: "width 0.3s ease, left 0.3s ease", // Animation for width and position
      zIndex: -1, // Position the pseudo-element behind the text
    },
    "&:hover::after": {
      width: "80%",
    },
    "&:hover": {
      color: "#fff", // Set the text color to white on hover
    },
  },
  text: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.875rem", // Adjust font size for small screens
    },
  },
  text1: {
    margin: theme.spacing(0.7, 0),
    [theme.breakpoints.down("sm")]: {},
  },
  text2: {
    margin: theme.spacing(0.1, 0),
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    [theme.breakpoints.down("sm")]: {},
    padding: "11px 11px 11px 0px",
    "&:hover": {
      backgroundColor: "#F4F4F4",
      width: "90%",
    },
  },
  divider: {
    marginTop: "15px",
  },
}));

const MegaMenu = ({ isOpen, onClose }) => {
  const classes = useStyles();
  const { filter, setFilter, fetchJobs } = useContext(JobContext);
  const [filters, setFilters] = useState("");
  const [categoriesData, setCategoriesData] = useState([]);
  const [locations, setLocations] = useState([]);
  const [industry, setIndustry] = useState([]);
  const menuRef = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const categoriesResponse = await getApiHandler("getIndustriesSector");
        const locationsResponse = await getApiHandler("listJobLocations");
        const industryResponse = await getApiHandler("getIndustries");
        setCategoriesData(categoriesResponse?.result || []);
        setLocations(locationsResponse?.result || []);
        setIndustry(industryResponse?.result || []);
        // console.log(industryResponse,"())))(((((((((((((((((((((((((")

        if (industryResponse?.result?.length) {
          setFilters(industryResponse.result[0]._id);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen, onClose]);

  const handleClick = (_id) => {
    setFilters(_id);
  };

  const filterCategoriesByCareer = () => {
    return categoriesData.filter(
      (category) => category.industry._id === filters
    );
  };

  // console.log("Koca: filterCategoriesByCareer ", filterCategoriesByCareer());

  // useEffect(() => {
  //   fetchJobs();
  // }, [filter]);

  return (
    <Box
      className={`${classes.megaMenu} ${isOpen ? classes.megaMenuVisible : ""}`}
    >
      <Box style={{ paddingLeft: "5.8%" }}>
        {industry?.length > 0 ? (
          <Box className={classes.megaMenuItem}>
            <Box style={{ marginRight: "25px", whiteSpace: "nowrap" }}>
              <Typography
                variant="h5"
                color="#60687B"
                style={{ whiteSpace: "nowrap" }}
              >
                Jobs by Industry
              </Typography>
            </Box>
            <Grid container style={{ gap: "10px" }}>
              {industry?.map((item) => (
                <Box key={item._id} onClick={() => handleClick(item._id)}>
                  <Typography
                    variant="h5"
                    color="#000000CC"
                    className={classes.textItem}
                    style={{
                      background: filters === item._id ? "#5E58E4" : "#F3FBFF",
                      color: filters === item._id ? "#fff" : "#000000CC",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {item.industryName}
                  </Typography>
                </Box>
              ))}
            </Grid>
          </Box>
        ) : (
          <NoDataFound />
        )}
        {filterCategoriesByCareer().length > 0 && (
          <>
            <Divider className={classes.divider} />
            <br />
            <Box style={{ marginTop: "10px !important" }}>
              <Typography variant="h6" color="#60687B">
                Jobs by Industry Sector
              </Typography>
            </Box>
            <Grid
              container
              // spacing={2}
              style={{ marginTop: "10px" }}
            >
              {filterCategoriesByCareer()?.map((category, index) => (
                // {categoriesData?.map((category, index) => (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={3}
                  key={index}
                  style={{ gap: "10px" }}
                >
                  <Link to={"/job-search"} style={{ textDecoration: "none" }}>
                    <Typography
                      variant="h5"
                      // className={classes.text2}
                      // align="left"
                      className={classes.text2}
                      // style={{
                      //   background: filter === category.jobCategory && "#5E58E4",
                      //   color: filter === category.jobCategory && "#fff",
                      // }}
                      // style={{
                      //   background: "#5E58E4",
                      //   color: "#fff",
                      // }}
                      onClick={() => {
                        const array = [category.industrySectorName];
                        setFilter((prev) => ({
                          ...prev,
                          ["industrySector"]: array,
                        }));
                        onClose();
                      }}
                    >
                      {" "}
                      {category.industrySectorName}
                    </Typography>
                  </Link>
                </Grid>
              ))}
            </Grid>
          </>
        )}
        {locations?.length > 0 && (
          <>
            <Divider className={classes.divider} />
            <br />
            <Grid>
              <Typography variant="h6" color="#60687B">
                Jobs by Location
              </Typography>
            </Grid>
            <Grid
              style={{ display: "", flexWrap: "wrap", marginBottom: "30px" }}
            >
              <Grid>
                <Grid container direction="row" style={{ marginTop: "10px" }}>
                  {locations?.map((country, countryIndex) =>
                    country.location.map((loc, index) => (
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={3}
                        key={`${countryIndex}-${index}`}
                      >
                        <Link
                          // to={loc.link || "#"}
                          to={"/job-search"}
                          style={{ textDecoration: "none" }}
                          onClick={() => {
                            setFilter((prev) => ({
                              ...prev,
                              ["location"]: loc.location,
                            }));
                            onClose();
                          }}
                        >
                          <Typography variant="h5" className={classes.text2}>
                            {loc.location}
                          </Typography>
                        </Link>
                      </Grid>
                    ))
                  )}
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
      </Box>
    </Box>
  );
};

export default MegaMenu;
