import React, { useState, useEffect, useContext } from "react";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  makeStyles,
  Grid,
} from "@material-ui/core";
import typography from "../../theme/typography";
import CloseJobImg from "../../assets/CloseJob.png";
import CommonRecruiterModal from "./CommonRecruiterModal";
import { Cancel } from "@material-ui/icons";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { RecruiterAuthContext } from "../../context/recruiterAuth";

const useStyles = makeStyles(() => ({
  noHover: {
    "&:hover": {
      backgroundColor: "rgba(91, 90, 91, 1)",
      boxShadow: "none",
      color: "white",
    },
  },
  modalTitle: {
    fontSize: "28px",
    fontWeight: 600,
    color: "rgba(12, 17, 29, 1)",
  },
  dialog: {
    //height:"349px",
    "& .MuiDialog-paper": {
      borderRadius: "10px !important",
    },
    "& .MuiDialogContent-root:first-child": {
      paddingTop: "0px",
    },
    "& .MuiDialogContent-root": {
      // paddingLeft:"20px",
      // paddingRight:"20px"
    },
  },
}));

const ResponsiveLogoutDialog = ({ open, onClose }) => {
  const { recruiterLogout } = useContext(RecruiterAuthContext);
  const history = useHistory();

  const handleCloseJobPost = () => {
    recruiterLogout();
    history.push("/recruiterlandingpage");
  };

  const handleNoButtonClick = () => {
    onClose(); // Close the DeleteJobModal when 'No' is clicked
  };

  const classes = useStyles();

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth={"515px"}
        className={classes.dialog}
      >
        <Box style={{ padding: "20px 20px" }}>
          <DialogContent
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              textAlign: "center",
              gap: "16px",
            }}
          >
            <img
              src="images/recruitLogout.png"
              alt="Logout"
              style={{ width: "auto", height: "100%", objectFit: "contain" }}
            />
            <Typography classes={{ root: classes.modalTitle }}>
              Log Out
            </Typography>
            <Typography style={typography.modalMessage}>
              Are you sure you want to log out?
            </Typography>
          </DialogContent>
          <DialogActions
            style={{ justifyContent: "center", gap: "4%", marginTop: "12px" }}
          >
            <Button
              variant="contained"
              color="secondary"
              style={{
                backgroundColor: "rgba(91, 90, 91, 1)",
                border: "none",
                width: "42%",
                borderRadius: "15px",
              }}
              onClick={handleNoButtonClick}
              classes={{ root: classes.noHover }}
              // fullWidth
            >
              No
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleCloseJobPost}
              style={{ width: "42%", borderRadius: "15px" }}
            >
              Yes
            </Button>
          </DialogActions>
        </Box>
        <Box
          style={{
            position: "absolute",
            bottom: -16,
            left: "50%",
            transform: "translateX(-50%)",
            height: "16px",
            backgroundColor: "#5E58E4",
            width: "77%",
            borderBottomLeftRadius: "16px",
            borderBottomRightRadius: "16px",
          }}
        />
      </Dialog>
    </>
  );
};

export default ResponsiveLogoutDialog;
