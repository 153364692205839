import React, { useState, useContext, useEffect } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  makeStyles,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import CloseIcon from "@material-ui/icons/Close";
import { AuthContext } from "../../context/Auth";

const useStyles = makeStyles((theme) => ({
  mainBox: {
    width: "460px",
    height: "349px",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      width: "100%", // Adjust width for small screens
      height: "auto", // Allow height to adjust based on content
      padding: theme.spacing(2), // Add padding for small screens
    },
  },
  subBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "15px",
    gap: "25px",
  },
  image: {
    width: "63px",
    height: "67px",
    [theme.breakpoints.down("sm")]: {
      width: "50px", // Smaller image size for small screens
      height: "50px",
    },
  },
  fadeInBox: {
    width: "100px",
    height: "100px",
    borderRadius: "50%",
    backgroundColor: "#f50057",
    animation: "$fadeIn 1s infinite alternate",
  },
  "@keyframes fadeIn": {
    from: { opacity: 0.2 },
    to: { opacity: 1 },
  },
  dialogheading: {
    fontSize: "28px",
    fontWeight: "600",
    color: "#161E29",
  },
  dialogsubheading: {
    lineHeight: "36px",
    fontSize: "28px",
    fontWeight: "600",
    color: "#0C111D",
    [theme.breakpoints.down("sm")]: {
      fontSize: "24px", // Smaller font size for small screens
      lineHeight: "30px",
    },
  },
  dialogsubheading1: {
    lineHeight: "22px",
    fontSize: "16px",
    fontWeight: "400",
    color: "#344054",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px", // Smaller font size for small screens
      lineHeight: "20px",
    },
  },
  btnBox: {
    display: "flex",
    gap: "20px",
    flexDirection: "row",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      flexDirection: "row",
      gap: "10px",
    },
  },
  btn1: {
    marginTop: "10px",
    width: "200px",
    height: "52px",
    lineHeight: "20px",
    fontSize: "16px",
    fontWeight: "600",
    borderRadius: "8px",
    padding: "8px 16px",
    [theme.breakpoints.down("sm")]: {
      width: "50%",
    },
  },
  btn2: {
    lineHeight: "20px",
    fontSize: "16px",
    fontWeight: "600",
    marginTop: "10px",
    width: "200px",
    height: "52px",
    border: "1px solid #797979",
    backgroundColor: "#797979",
    borderRadius: "8px",
    padding: "8px 16px",
    color: "#fff",
    [theme.breakpoints.down("sm")]: {
      width: "50%",
    },
    "&:hover": {
      border: "1px solid #797979",
      boxShadow: "none",
      color: "#797979",
      backgroundColor: "#fff",
    },
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[900],
  },
  dialog: {
    "& .MuiDialog-paper": {
      borderRadius: "32px !important",
    },
  },
}));

const SeekerLogout = ({ open, onClose }) => {
  const classes = useStyles();
  const history = useHistory();
  const { userLogout } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  console.log("Koca: isLoading ", isLoading);

  useEffect(() => {
    console.log(isLoading, "isLoading updated");
  }, [isLoading]);

  const handleLogOut = async () => {
    setIsLoading(true);
    try {
      await userLogout(); // Ensure userLogout is async
    } catch (error) {
      console.error("Error during logout:", error);
    } finally {
      setTimeout(() => {
        setIsLoading(false);
        history.push("/");
      }, 500);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={classes.dialog}
    >
      <IconButton
        aria-label="close"
        className={classes.closeButton}
        onClick={onClose}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Box className={classes.mainBox}>
          <Box className={classes.subBox}>
            <img
              src="images/logout.png"
              className={classes.image}
              alt="Logout"
            />
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                gap: "15px",
              }}
            >
              <Typography className={classes.dialogsubheading}>
                Log Out
              </Typography>
              <Typography className={classes.dialogsubheading1}>
                Are you sure, you want to log out?
              </Typography>
            </Box>
            <Box className={classes.btnBox}>
              <Button
                variant="outlined"
                color="default"
                className={classes.btn2}
                onClick={onClose}
              >
                No
              </Button>
              <Button
                variant="contained"
                color="secondary"
                className={classes.btn1}
                onClick={handleLogOut}
              >
                {isLoading ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  "Yes"
                )}
              </Button>
            </Box>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default SeekerLogout;
