import * as Yup from "yup";
const zipRegex = /^[A-Za-z0-9]{2,10}([- ]?[A-Za-z0-9]{2,10})?$/;
const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;
const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
const fullNameRegex = /^[A-Za-z\s]{1,50}$/;
const companyregex = /^(?!\s)(?!.*\s{2,})[A-Za-z0-9!@#$%^&*()_+={}\[\]|\\:;"'<>,.?/-]+(?=\S)$/;



export const  PostJobSchema = Yup.object({
  jobTitle: Yup.string()
    .required("Please enter the Title.")
    .trim(),
    

  jobDescription: Yup.string()
    .required("Please enter the job description.")
    .trim()
    .min(100, "Description cannot be less than 100 characters."),
    // .max(2000, "Description cannot be more than 2000 characters."),

  skills: Yup.array()
    .of(Yup.string().required("Please select at least one skill."))
    .min(1, "Please select at least one skill."),

  jobType: Yup.string().required("Please select job type.").trim(),

  salary: Yup.object({
    currency: Yup.string().required("Please select a currency.").trim(),
    minSalary: Yup.number()
      .required("Please enter minimum salary.")
      .positive("Minimum salary must be a positive number.")
      .integer("Minimum salary must be an integer."),
    maxSalary: Yup.number()
      .required("Please enter maximum salary.")
      .positive("Maximum salary must be a positive number.")
      .integer("Maximum salary must be an integer.")
      .moreThan(
        Yup.ref("minSalary"),
        "Maximum salary must be greater than minimum salary."
      ),
  }),

  experience: Yup.object({
    minExperience: Yup.number()
      .required("Please enter minimum experience.")
      .positive("Minimum experience must be a positive number.")
      .integer("Minimum experience must be an integer."),
    maxExperience: Yup.number()
      .required("Please enter maximum experience.")
      .positive("Maximum experience must be a positive number.")
      .integer("Maximum experience must be an integer.")
      .moreThan(
        Yup.ref("minExperience"),
        "Maximum experience must be greater than minimum experience."
      ),
  }),

  careerLevel: Yup.string().required("Please select career level.").trim(),

  gender: Yup.string().required("Please select gender.").trim(),

  industry: Yup.string().required("Please select industry.").trim(),
  industrySector: Yup.string()
    .required("Please select industry sector.")
    .trim(),
  jobCategory: Yup.string().required("Please select job category.").trim(),
  city: Yup.string().required("Please select city.").trim(),
  openings: Yup.number()
    .typeError("Openings must be a number.")
    .required("Please enter number of openings.")
    .positive("Openings must be a positive number.")
    .integer("Maximum openings must be an integer."),

  qualification: Yup.array()
    .of(Yup.string().required("Please select at least one qualification."))
    .min(1, "Please select at least one qualification."),

  country: Yup.string().required("Please select country.").trim(),

  deadlineDate: Yup.string().required("Please select deadline date.").trim(),

  // address: Yup.string()
  //   .required("Please enter complete address.")
  //   .trim()
  //   .min(2, "Address cannot be less than 2 characters.")
  //   .max(100, "Address cannot be more than 100 characters."),

  findOnMap: Yup.string().optional().trim(),

  latitude: Yup.string()
    .optional()
    .trim()
    .matches(/^(-?([0-8]?[0-9]|90)(\.\d+)?|(\d+))$/, "Invalid latitude format.")
    .test(
      "is-valid-latitude",
      "Latitude must be between -90 and 90",
      (value) => {
        if (!value) return true; // if value is empty, return true (optional)
        const numericValue = parseFloat(value);
        return numericValue >= -90 && numericValue <= 90;
      }
    ),

  longitude: Yup.string()
    .optional()
    .trim()
    .matches(
      /^(-?(180|1[0-7][0-9]|[0-9]{1,2})(\.\d+)?|(\d+))$/,
      "Invalid longitude format."
    )
    .test(
      "is-valid-longitude",
      "Longitude must be between -180 and 180",
      (value) => {
        if (!value) return true; // if value is empty, return true (optional)
        const numericValue = parseFloat(value);
        return numericValue >= -180 && numericValue <= 180;
      }
    ),
});

export const changePasswordSchema = Yup.object({
  oldPassword: Yup.string().required("Please enter the old password.").trim(),

  newPassword: Yup.string()
    .required("Please enter the new password.")
    .trim()
    .min(8, "Password must be at least 8 characters long."),

  confirmPassword: Yup.string()
    .required("Please confirm your password.")
    .trim()
    .oneOf([Yup.ref("newPassword"), null], "Passwords must match."),
});

export const EditPofileSchema = Yup.object({
  companyName: Yup.string().trim().required("Please enter the company name.")
  .max(80, "Company name cannot be more than 80 characters."),

  ownerName: Yup.string()
    .trim()
    .required("Please enter the recruiter name.")
    .matches(fullNameRegex, "Recruiter name can only contain letters and spaces.")
    .max(60, "Recruiter name cannot be more than 60 characters."),

  ownerEmail: Yup.string()
    .required("Please enter the recruiter email address.")
    .email("Please enter valid email address.")
    .matches(emailRegex, "Please enter valid email address."),

  companyTagline: Yup.string()
    .optional("Please enter the company tagline name.")
    .min(2, "Company tagline cannot be less than 2 characters.")
    .max(100, "Company tagline cannot be more than 100 characters."),

  established: Yup.string()
    .optional("Please enter the established date.")
    .min(4, "Cannot be less than 4 characters."),
  // .max(64, "Title cannot be more than 64 characters."),

  // industry: Yup.string()
  //   // .optional(),
  //   .optional("Please select the industry."),
  // industrySector: Yup.string().optional("Please select an industry sector."),

  // jobCategory: Yup.string().optional("Please select a job category."),

  logo: Yup.string()
    .required("Please upload company logo."),

  gstin: Yup.string().optional("Please upload GSTIN."),

  pan: Yup.string().optional("Please upload company PAN."),

  iec: Yup.string().optional("Please upload company IEC."),

  email: Yup.string()
    .required("Please enter the company email address.")
    .email("Please enter valid email address.")
    .matches(emailRegex, "Please enter valid email address."),

  websiteLink: Yup.string()
    .optional("Please enter the company website link.")
    .trim()
    .url("Please enter valid URL format."),

  address: Yup.string()
    .optional("Please enter the company address.")
    .trim()
    .min(2, "Company address cannot be less than 2 characters.")
    .max(260, "Address should not exceed 260 characters."),

  address2: Yup.string().optional().trim(),

  state: Yup.string()
    // .optional()
    .optional("Please select state.")
    .trim(),

  employee: Yup.string().optional("Please enter number of employees.").trim(),

  phoneNumber: Yup.string()
    .optional("Please enter phone number.")
    .matches(/^[0-9]{6,12}$/, "Phone number must be between 6 and 12 digits.")
    .test(
      "not-all-same",
      "Phone number cannot be all the same digit.",
      (value) => !/^(\d)\1{5,11}$/.test(value) // This checks if all digits are the same
    ),

  landline: Yup.string()
    .optional("Please enter a landline number.")
    .matches(
      /^[0-9]{6,12}$/,
      "Landline number must be between 6 and 12 digits."
    )
    .test(
      "valid-format",
      "Landline number cannot be all the same digit.",
      (value) => !/^(\d)\1{5,11}$/.test(value) // Disallow all same digits (e.g., 111111)
    )
    .matches(/^[^0]+/, "Landline number cannot start with zero.") // In some regions
    .matches(
      /^[\d-()\s]+$/,
      "Landline number can only contain digits, spaces, dashes, or parentheses."
    ),

  zip: Yup.string()
    .optional("Please enter a ZIP/Postal code.")
    .matches(
      /^[0-9]{4,10}$/,
      "Zip code number must be between 4 and 10 digits."
    )
    .matches(
      /^[a-zA-Z0-9-\s]+$/,
      "ZIP code can only contain letters, numbers, spaces, or dashes."
    )
    .test(
      "not-all-same",
      "ZIP code cannot be all the same character.",
      (value) => !/^(\d)\1+$/.test(value) // Prevents repeated characters like '11111'
    ),
  city: Yup.string()
    // .optional()
    .optional("Please select city.")
    .trim(),

  country: Yup.string()
    // .optional()
    .optional("Please select country.")
    .trim(),

  workingHours: Yup.string().optional("Please enter working hours.").trim(),

  facebook: Yup.string().optional().trim().url("Invalid URL format"),

  twitter: Yup.string().optional().trim().url("Invalid URL format"),

  linkedIn: Yup.string().optional().trim().url("Invalid URL format"),

  instagram: Yup.string().optional().trim().url("Invalid URL format"),

  description: Yup.string()
    .optional("Please enter description.")
    .trim()
    .max(2400, "Description should not exceed 2400 characters."),
});

export const registerCompany = Yup.object({
  companyName: Yup.string().trim().required("Please enter the company name.")
  .max(80, "Company name cannot be more than 80 characters."),

  ownerName: Yup.string()
    .trim()
    .required("Please enter the recruiter name.")
    .matches(fullNameRegex, "recruiter name can only contain letters and spaces.")
    .max(60, "Recruiter name cannot be more than 60 characters."),

  ownerEmail: Yup.string()
    .required("Please enter the  recruiter email address.")
    .email("Please enter valid email address."),

  companyTagline: Yup.string()
    .trim()
    
    .min(2, "Company tagline cannot be less than 2 characters.")
    .max(100, "Company tagline cannot be more than 100 characters."),

  established: Yup.string()
    .optional("Please enter the established date.")
    .min(4, "Cannot be less than 4 characters."),
  // .max(64, "Title cannot be more than 64 characters."),

  industry: Yup.string()
    // .optional(),
    .optional("Please select the industry."),
  industrySector: Yup.string().optional("Please select an industry sector."),

  jobCategory: Yup.string().optional("Please select a job category."),

  logo: Yup.string()
    .required("Please upload company logo."),

  gstin: Yup.string().notRequired(), // Updated optional

  pan: Yup.string().notRequired(), // Updated optional

  iec: Yup.string().notRequired(), // Updated optional

  email: Yup.string()
    .required("Please enter the company email address.")
    .email("Please enter valid email address."),
    

  websiteLink: Yup.string()
    .optional("Please enter the company website link.")
    .trim()
    .url("Please enter valid URL format."),

  address: Yup.string()
    .optional("Please enter the company address.")
    .trim()
    .min(2, "Company address cannot be less than 2 characters.")
    .max(260, "Address should not exceed 260 characters."),

  address2: Yup.string().optional().trim(),

  state: Yup.string()
    // .optional()
    .optional("Please select state.")
    .trim(),

  employee: Yup.string().optional("Please enter number of employees.").trim(),

  phoneNumber: Yup.string()
    .optional("Please enter phone number.")
    .matches(/^[0-9]{6,12}$/, "Phone number must be between 6 and 12 digits.")
    .test(
      "not-all-same",
      "Phone number cannot be all the same digit.",
      (value) => !/^(\d)\1{5,11}$/.test(value) // This checks if all digits are the same
    ),

  landline: Yup.string()
    .optional("Please enter a landline number.")
    .matches(
      /^[0-9]{6,12}$/,
      "Landline number must be between 6 and 12 digits."
    )
    .test(
      "valid-format",
      "Landline number cannot be all the same digit.",
      (value) => !/^(\d)\1{5,11}$/.test(value) // Disallow all same digits (e.g., 111111)
    )
    .matches(/^[^0]+/, "Landline number cannot start with zero.") // In some regions
    .matches(
      /^[\d-()\s]+$/,
      "Landline number can only contain digits, spaces, dashes, or parentheses."
    ),

  zip: Yup.string()
    .optional("Please enter a ZIP/Postal code.")
    .matches(
      /^[0-9]{4,10}$/,
      "Zip code number must be between 4 and 10 digits."
    )
    .matches(
      /^[a-zA-Z0-9-\s]+$/,
      "ZIP code can only contain letters, numbers, spaces, or dashes."
    )
    .test(
      "not-all-same",
      "ZIP code cannot be all the same character.",
      (value) => !/^(\d)\1+$/.test(value) // Prevents repeated characters like '11111'
    ),

  city: Yup.string()
    // .optional()
    .optional("Please select city.")
    .trim(),

  country: Yup.string()
    // .optional()
    .optional("Please select country.")
    .trim(),

  workingHours: Yup.string().optional("Please enter working hours.").trim(),

  facebook: Yup.string().optional().trim().url("Invalid URL format."),

  twitter: Yup.string().optional().trim().url("Invalid URL format."),

  linkedIn: Yup.string().optional().trim().url("Invalid URL format."),

  instagram: Yup.string().optional().trim().url("Invalid URL format."),

  description: Yup.string()
    .optional("Please enter description.")
    .trim()
    .max(2400, "Description should not exceed 2400 characters."),

  password: Yup.string()
    .required("Please enter the password.")
    .trim()
    .min(8, "Password must be at least 8 characters long."),

  confirmPassword: Yup.string()
    .required("Please confirm your password.")
    .trim()
    .oneOf([Yup.ref("password"), null], "Passwords do not match."),
});

export const validationSchema = Yup.object().shape({
  img: Yup.string().required("Please upload image."),
  firstName: Yup.string().required("Please enter first name."),
  lastName: Yup.string().required("Please enter last name."),
  email: Yup.string()
    .email("Invalid email address.")
    .required("Please enter email address."),
  phone: Yup.string().required("Please enter phone number."),
  country: Yup.string().required("Please select country."),
  state: Yup.string().required("Please select state."),
  city: Yup.string().required("Please select city."),
  completeAddress: Yup.string().required("Please enter complete address."),
  jobTitle: Yup.string().required("Please enter job title."),
  companyName: Yup.string().required("Please enter company name."),
  startDate: Yup.date().required("Please enter start date."),
  endDate: Yup.date().required("Please enter end date."),
  startEduDate: Yup.date().required("Please enter start date."),
  endEduDate: Yup.date().required("Please enter end date."),
  qualification: Yup.string().required("Please select qualification."),
  institute: Yup.string().required("Please select institute."),
  fieldOfStudy: Yup.string().required("Please select field of study."),
  certificateName: Yup.string().required("Please select certificate name."),
  issuingDate: Yup.date().required("Please enter issuing date."),
  language: Yup.string().required("Please select language."),
  proficiencyLevel: Yup.string().required("Please select proficiency level."),
});
