import React, { createContext, useEffect, useState } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";

export const AuthContext = createContext();

const setSession = (accessToken) => {
  if (accessToken) {
    sessionStorage.setItem("token", accessToken); // Store the token in sessionStorage
    sessionStorage.setItem("isLogin", true);
  } else {
    sessionStorage.removeItem("token"); // Remove the token from sessionStorage
    // delete axios.defaults.headers.common.Authorization;
  }
};

const checkLogin = () => {
  const isAuth = sessionStorage.getItem("token");
  const isLogin = sessionStorage.getItem("isLogin");
  return isAuth && isLogin === "true" ? true : false;
};

export default function AuthProvider(props) {
  const history = useHistory();
  const [isLogin, setIsLogin] = useState(checkLogin());
  const [userData, setUserData] = useState({});

  const userLogIn = (token, data) => {
    setSession(token); // Store the token in sessionStorage
    setIsLogin(true);
    setUserData(data);
  };

  const userLogout = () => {
    setSession(null);
    setIsLogin(false);
    setUserData({});
    sessionStorage.clear();
    localStorage.clear();
  };
  // const token = sessionStorage.getItem("token");
  // useEffect(() => {
  //   if (token) {
  //     setIsLogin(true);
  //   } else {
  //     setIsLogin(false);
  //   }
  // }, [token]);

  const authContextValue = {
    isLogin,
    userData,
    userLogIn,
    userLogout,
  };

  return (
    <AuthContext.Provider value={authContextValue}>
      {props.children}
    </AuthContext.Provider>
  );
}
