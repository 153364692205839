import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { Formik, Form } from "formik";
import * as yup from "yup";
import { AuthContext } from "../../../../context/Auth";
import SignUp from "./SignUp";
import Popup from "../../../../component/modals/LoginModal";
import VisibilityIcon from "@material-ui/icons/Visibility";
import toast from "react-hot-toast";
import { postApiHandler } from "../../../../config/service";
import OAuth from "../../../../component/OAuth";

const useStyles = makeStyles((theme) => ({
  authContainer: {
    display: "flex",
    flexDirection: "row",
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#AFB0B6",
    },
  },
  formContainer: {
    borderRadius: "20px 0px 0px 20px",
    backgroundColor: "white",
    padding: "40px",

    "@media(max-width:1030px)": {
      padding: theme.spacing(4),
      borderRadius: "20px",
    },
    "@media(max-width:768px)": {
      padding: theme.spacing(4),
      borderRadius: "20px",
    },
    "@media(max-width:430px)": {
      borderRadius: "20px",
      padding: theme.spacing(2),
    },
    position: "relative",
  },
  imageSection: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "@media(max-width:1030px)": {
      display: "none",
      borderRadius: "20px",
    },
    "@media(max-width:430px)": {
      display: "none",
      borderRadius: "20px",
    },
  },
  image: {
    objectFit: "contain",
    borderRadius: "0px 20px 20px 0px",
    height: "100%",
    "@media(max-width:768px)": {
      width: "100%",
      objectFit: "contain",
      height: "100%",
      borderRadius: "0px 20px 20px 0px",
    },
  },

  heading: {
    fontWeight: 700,
    lineHeight: "29px",
    fontSize: 24,
    fontFamily: "Inter",
    color: "#262626",
    "@media(max-width:1200px)": {
      fontSize: 22,
      lineHeight: "28px",
    },
    "@media(max-width:992px)": {
      fontSize: 18,
      lineHeight: "24px",
    },
    "@media(max-width:767px)": {
      fontSize: 16,
      lineHeight: "18px",
    },
  },
  heading1: {
    lineHeight: "20px",
  },
  remember: {
    marginTop: "5px",
    display: "flex",
    justifyContent: "space-between",
    "@media(max-width:1024px)": {
      gap: "52px",
    },
    "@media(max-width:768px)": {
      gap: "42px",
    },
    "@media(max-width:430px)": {
      gap: "32px",
    },
    "& .MuiFormControlLabel-root": {
      marginLeft: "-12px",
    },
  },
  staticContent: {
    top: "51px",
    width: "29%",
    position: "absolute",
    color: "white",
    padding: theme.spacing(0),
  },
  staticheading1: {
    fontWeight: 400,
    fontSize: 20,
    lineHeight: "24px",
    color: "#FFFFFF",
    fontFamily: "Inter",
    marginBottom: theme.spacing(1),
  },
  staticheading2: {
    fontWeight: 700,
    fontSize: 20,
    lineHeight: "24px",
    color: "#FFFFFF",
    fontFamily: "Inter",
  },
  closeButton: {
    position: "absolute",
    top: theme.spacing(-3),
    right: theme.spacing(-57),
    height: "31px",
    width: "31px",
    color: theme.palette.grey[900],
    backgroundColor: "white",
    "@media(max-width:1030px)": {
      top: "-19PX",
      right: "-28PX",
    },
    "&:hover": {
      color: theme.palette.grey[900],
      backgroundColor: "white",
    },
  },
  checkbox: {
    color: "#AFAFAF",
    "&.Mui-checked": {
      color: "#5E58E4",
    },
  },
}));

const Login = ({
  open,
  onClose,
  handleSignUpClick,
  handleForgotClick,
  handletestClick,
  setEmail,
  redirectUrl,
}) => {
  const { userLogIn } = useContext(AuthContext);
  const classes = useStyles();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [isRememberMe, setIsRememberMe] = useState(false);
  const [openSignup, setOpenSignup] = React.useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [onOpen, setOnOpen] = useState(false);

  const handleSignupOpen = () => {
    setOpenSignup(false);
    onClose();
    handleSignUpClick();
  };
  const handleSignupClose = () => setOpenSignup(false);
  const handleForgotOpen = () => {
    onClose();
    handleForgotClick();
  };
  const handleOTPOpen = () => {
    onClose();
    console.log("handle otp clicked", handletestClick);
    handletestClick();
  };
  const formInitialSchema = {
    email: "",
    password: "",
  };

  const formValidationSchema = yup.object().shape({
    email: yup
      .string()
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        "Please enter a valid email."
      )
      .max(256, "Should not exceed 256 characters.")
      .required("Please enter your email address."),

    password: yup.string().required("Please enter your password."),
  });

  const validateEmail = async (email) => {
    const isValid = formValidationSchema.isValidSync({ email });
    return isValid;
  };

  const handleFormSubmit = async (values) => {
    const data = {
      email: values.email,
      password: values.password,
    };
    setIsLoading(true);
    try {
      const res = await postApiHandler("seekerLogin", data);
      if (res?.result?.status === "BLOCKED") {
        toast.error("You are blocked by the admin");
      } else if (res?.responseCode === 200) {
        console.log(res);
        const token = res?.result?.token;
        userLogIn(token, true);
        sessionStorage.setItem("user", JSON.stringify(res.result));
        if (token) {
          if (isRememberMe) {
            localStorage.setItem("email", values.email);
          }
          toast.success("Login successful.");
          setEmail(values.email);
          onClose();
          history.push(redirectUrl ? redirectUrl : "/dashboard");
        }
      } else if (res?.responseCode === 401) {
        setIsLoading(false);
        toast.success("User not verified");
        setEmail(values.email);
        handleOTPOpen();
      } else {
        toast.error(res?.responseMessage);
      }
    } catch (error) {
      console.log("Koca: error ", error);
      toast.error("Login failed. Please try again.");
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Popup open={open} handleClose={onClose}>
        <Box className={classes.authContainer}>
          <Box className={classes.formContainer}>
            <Box style={{}}>
              <Typography
                variant="body6"
                align="left"
                className={classes.heading}
              >
                Login Now
              </Typography>
              <Box my={1.5}>
                <Typography variant="h6" className={classes.heading1}>
                  Your dream job is just a step away. Log in to access tailored
                  opportunities and accelerate your career growth.{" "}
                </Typography>
              </Box>

              <Formik
                initialValues={formInitialSchema}
                validationSchema={formValidationSchema}
                onSubmit={(values) => handleFormSubmit(values)}
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  touched,
                  values,
                }) => (
                  <Form>
                    <Box mt={1.5}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        placeholder="Enter email address"
                        name="email"
                        id="email"
                        inputProps={{
                          maxLength: 62,
                        }}
                        value={values.email}
                        error={Boolean(touched.email && errors.email)}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        onInput={(e) => {
                          let value = e.target.value;
                          value = value.replace(/\s/g, "").toLowerCase();
                          e.target.value = value;
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Box display="flex" alignItems="center">
                                <img
                                  src="/images/email.svg"
                                  height={"20px"}
                                  width={"20px"}
                                  style={{ color: "#00000080" }}
                                />
                                <Divider
                                  orientation="vertical"
                                  flexItem
                                  style={{ height: "34px", margin: "0 15px" }}
                                />
                              </Box>
                            </InputAdornment>
                          ),
                        }}
                      />

                      <FormHelperText error>
                        {touched.email && errors.email}
                      </FormHelperText>
                    </Box>

                    <Box mt={2}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        placeholder="Enter Password"
                        type={showPassword ? "text" : "password"}
                        name="password"
                        id="password"
                        inputProps={{
                          maxLength: 62,
                          onCopy: (e) => e.preventDefault(), // Prevent copy
                          onPaste: (e) => e.preventDefault(), // Prevent paste
                        }}
                        value={values.password}
                        error={Boolean(touched.password && errors.password)}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        onInput={(e) => {
                          const regex = /^[^\s]*$/;
                          if (!regex.test(e.target.value)) {
                            e.target.value =
                              e.target.dataset.previousValue || "";
                          } else {
                            e.target.dataset.previousValue = e.target.value;
                          }
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Box display="flex" alignItems="center">
                                <img
                                  src="/images/lock1.svg"
                                  height={"20px"}
                                  width={"20px"}
                                  style={{ color: "#00000080" }}
                                />
                                <Divider
                                  orientation="vertical"
                                  flexItem
                                  style={{ height: "34px", margin: "0 15px" }}
                                />
                              </Box>
                            </InputAdornment>
                          ),
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={() => setShowPassword(!showPassword)}
                                edge="end"
                              >
                                <Box>
                                  {showPassword ? (
                                    <VisibilityIcon
                                      className={classes.iconClass1}
                                      style={{ width: "18px", height: "18px" }}
                                    />
                                  ) : (
                                    <img
                                      src="/images/eyeP.svg"
                                      style={{
                                        width: "18px",
                                        height: "18px",
                                        objectFit: "contain",
                                      }}
                                    />
                                  )}
                                </Box>
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                      <FormHelperText error>
                        {touched.password && errors.password}
                      </FormHelperText>
                    </Box>

                    <Grid
                      container
                      alignItems="center"
                      className={classes.remember}
                    >
                      <Grid item>
                        <FormControlLabel
                          control={
                            <Checkbox
                              className={classes.checkbox}
                              checked={isRememberMe}
                              onChange={(e) =>
                                setIsRememberMe(e.target.checked)
                              }
                              name="rememberMe"
                              color="primary"
                            />
                          }
                          label={
                            <Typography
                              variant="h5"
                              style={{
                                color: "#666666",
                                lineHeight: "16px",
                                marginLeft: "0px",
                                textWrap: "nowrap",
                              }}
                            >
                              Remember Me
                            </Typography>
                          }
                        />
                      </Grid>
                      <Grid item>
                        <Typography
                          variant="h5"
                          style={{
                            color: "#5E58E4",
                            textDecoration: "",
                            cursor: "pointer",
                          }}
                          onClick={handleForgotOpen}
                        >
                          Forgot Password?
                        </Typography>
                      </Grid>
                    </Grid>

                    <Box>
                      <Button
                        style={{
                          marginTop: "10px",
                          backgroundColor: "#5E58E4",
                          color: "white",
                          textTransform: "none",
                        }}
                        variant="contained"
                        color="secondary"
                        fullWidth
                        type="submit"
                        disabled={isLoading}
                      >
                        Log in
                        {isLoading && (
                          <CircularProgress
                            size={20}
                            style={{ color: "#fff", marginLeft: "10px" }}
                          />
                        )}
                      </Button>
                    </Box>

                    <Box
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "25px",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: "20px",
                      }}
                    >
                      <Typography variant="h5">OR</Typography>
                      <OAuth redirectUrl={redirectUrl} />

                      <Typography variant="h5">
                        Don't have an account?
                        <a
                          style={{
                            color: "#5E58E4",
                            cursor: "pointer",
                            marginLeft: "5px",
                            textDecoration: "none",
                          }}
                          onClick={handleSignupOpen}
                        >
                          Sign Up
                        </a>
                        <SignUp open={openSignup} onClose={handleSignupClose} />
                      </Typography>
                    </Box>
                  </Form>
                )}
              </Formik>
            </Box>
          </Box>
          <Box className={classes.imageSection}>
            <img
              src="images/otp.png"
              alt="Illustration"
              className={classes.image}
            />
            <Box className={classes.staticContent}>
              <p className={classes.staticheading1}>
                Very good works are waiting for you. ✌️
              </p>
              <h1 className={classes.staticheading2}>Login Now</h1>
            </Box>
          </Box>
        </Box>
      </Popup>
    </>
  );
};

export default Login;
