import React from "react";
import { styled } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: "20px",
    background: "none",
    // padding:"20px"
  },
}));

const CloseButton = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  right: -25,
  top: -25,
  color: "black",
  backgroundColor: "white",
  height: "31px",
  width: "31px",
  "&:hover": {
    backgroundColor: "white", // Change the background color on hover
    color: "black", // Change the text color on hover
  },
}));

const Popup = ({
  children,
  handleClose,
  handleLoginClose,
  open,
  loginOpen,
}) => {
  return (
    <BootstrapDialog
      onClose={handleClose || handleLoginClose}
      open={open || loginOpen}
      disableBackdropClick // Prevents closing when clicking outside
      disableEscapeKeyDown // Prevents closing when pressing Escape
    >
      <CloseButton aria-label="close" onClick={handleClose || handleLoginClose}>
        <CloseIcon />
      </CloseButton>
      {children}
    </BootstrapDialog>
  );
};

export default Popup;
