import React, { createContext, useState } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";



// Create a context for recruiter authentication
export const RecruiterAuthContext = createContext();

// Function to set or remove the session token in session storage
const setSession = (accessToken) => {
  if (accessToken) {
    sessionStorage.setItem("token", accessToken);
    sessionStorage.setItem("isRecruiterLoggedIn", true);
    
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    sessionStorage.removeItem("token"); // Remove token from session storage
    delete axios.defaults.headers.common.Authorization;
  }
};

// Function to check if a recruiter is logged in by verifying the token in session storage
function checkRecruiterLogin() {
  const accessToken = sessionStorage.getItem("token");
  const isRecruiterLoggedIn= sessionStorage.getItem("isRecruiterLoggedIn");
  return accessToken && (isRecruiterLoggedIn==="true") ? true : false;
}

export default function RecruiterAuthProvider(props) {
  const history= useHistory();
  const [isRecruiterLoggedIn, setIsRecruiterLoggedIn] = useState(
    checkRecruiterLogin()
  );
  const [recruiterData] = useState({});

  let data = {
    recruiterLoggedIn: isRecruiterLoggedIn, // Indicates if recruiter is logged in
    isRecruiterLoggedIn, // Method to update login status
    recruiterData, // Contains recruiter-specific data
    recruiterLogIn: (token) => {
      setSession(token); // Save the token in session storage
      setIsRecruiterLoggedIn(true); // Set login state
      
    },
    recruiterLogout: () => {
      setSession(null); // Clear the token from session storage
      sessionStorage.removeItem("isRecruiterLoggedIn");
      setIsRecruiterLoggedIn(false); // Set login state to false
     
    },
  };

  return (
    <RecruiterAuthContext.Provider value={data}>
      {props.children}
    </RecruiterAuthContext.Provider>
  );
}
