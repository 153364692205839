
import React from "react";
import { Box } from "@material-ui/core";
import BeforeLoginHeader from '../../component/recruiterHeader/BeforeLoginHeader';
import RecruiterFooter from "../../component/RecruiterFooter";

function RecruiterHomeLayout({ children }) {
    return (
        <div>
            <Box>
                <Box>
                    <BeforeLoginHeader />
                </Box>
                {children}
                <Box>
                    <RecruiterFooter />
                </Box>
            </Box>
        </div>
    );
}

export default RecruiterHomeLayout;
