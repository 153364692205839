import React from "react";
import { Grid, CssBaseline, makeStyles, Box } from "@material-ui/core";
import RecruiterLoginHeader from "../../component/recruiterHeader/RecruiterLoginHeader";


function RecruiterPricingLayout({ children }) {
    return (
        <div>
            <Box>
                <Box>
                    <RecruiterLoginHeader />
                </Box>

                <Box>
                    {children}
                </Box>
            </Box>
        </div>
    );
}

export default RecruiterPricingLayout;
