
import React from "react";
import { Box } from "@material-ui/core";
import Footer from '../component/Footer';
import Header from '../component/header/Header';

function HomeLayout({ children }) {
    return (
        <>
            <div>
                <Box>
                    <Box>
                        <Header />
                    </Box>
                    <Box style={{ overflow: "hidden" }}>
                        {children}
                    </Box>

                    <Box>
                        <Footer />
                    </Box>
                </Box>
            </div>



        </>
    );
}

export default HomeLayout;
