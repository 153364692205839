import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  makeStyles,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Grid,
  CircularProgress,
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import typography from "../../../../theme/typography";
import { useFormik } from "formik";
import { changePasswordSchema } from "../../../../schema";
import CommonRecruiterModal from "../../../../component/modals/CommonRecruiterModal";
import CustomTextField from "../../../../component/CustomTextField";
import {
  postDataHandler,
  postDataHandlerWithToken,
} from "../../../../config/service";
import toast from "react-hot-toast";

const useStyles = makeStyles((theme) => ({
  dialog: {
    //height:"349px",
    "& .MuiDialog-paper": {
      borderRadius: "32px !important",
    },
    "& .MuiDialogContent-root:first-child": {
      paddingTop: "0px",
    },
  },
  dialogContainer: {
    padding: "5% 2%",
    display: "flex",
    flexDirection: "column",
    gap: "20px",
  },
  dialogContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    gap: "20px",
  },
  dialogContents: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
  },
  modalTitle: {
    fontSize: 28,
    fontWeight: 600,
    lineHeight: "36px",
    fontFamily: "Inter",
    color: "rgba(12, 17, 29, 1)",
    "@media(max-width:1200px)": {
      fontSize: 28,
    },
    "@media(max-width:992px)": {
      fontSize: 24,
    },
    "@media(max-width:767px)": {
      fontSize: 20,
    },
  },
  message: {
    fontSize: 16,
    fontWeight: 400,
    lineHeight: "22px",
    paddingTop: "3%",
    fontFamily: "Inter",
    color: "rgba(52, 64, 84, 1)",
    "@media(max-width:1200px)": {
      fontSize: 16,
    },
    "@media(max-width:992px)": {
      fontSize: 24,
    },
    "@media(max-width:767px)": {
      fontSize: 20,
    },
  },
  inputFieldContainer: {
    width: "100%",
    // border: '2px solid green',
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    alignItems: "start",
  },
  inputContentBox: {
    width: "100%",
    // border: '1px solid pink',
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    alignItems: "start",
  },
}));

const ChangePassword = ({ open, onClose, modalTitle, message, buttonText }) => {
  const Email = sessionStorage.getItem("email");
  const [isCommonRecruiterModalOpen, setIsCommonRecruiterModalOpen] =
    useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const classes = useStyles();
  // const [values, setValues] = React.useState({
  //     oldPassword: '',
  //     newPassword: '',
  //     confirmPassword: '',
  // });
  const [showPassword, setShowPassword] = React.useState({
    oldPassword: false,
    newPassword: false,
    confirmPassword: false,
  });

  const initialValues = {
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  };

  const handleFormSubmit = async (values, { resetForm }) => {
    const data = {
      currentPassword: values.oldPassword,
      newPassword: values.confirmPassword,
    };
    console.log(data);
    try {
      setIsLoading(true);
      const res = await postDataHandlerWithToken("changePassword", data);
  
      if (res?.responseCode === 200) {
        setIsCommonRecruiterModalOpen(true);
        resetForm(); // Reset the form after successful submission
      } else {
        toast.error(res?.data?.responseMessage);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };
  

  const { values, errors, handleBlur, handleChange, touched, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      validationSchema: changePasswordSchema,
      onSubmit: handleFormSubmit,
    });
  console.log(values);

  const handleCommonRecruiterModalClose = () => {
    setIsCommonRecruiterModalOpen(false);
    onClose(); // Close the DeleteJobModal entirely when the CommonRecruiterModal is closed
  };

  const handleClickShowPassword = (prop) => () => {
    setShowPassword({ ...showPassword, [prop]: !showPassword[prop] });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const onbuttonClick = () => {
    // Add your button click logic here
  };

  return (
    <>
      {!isCommonRecruiterModalOpen ? (
        <Dialog
          open={open}
          onClose={onClose}
          className={classes.dialog}
          maxWidth="sm"
        >
          <form onSubmit={handleSubmit}>
            <Box className={classes.dialogContainer}>
              <DialogContent className={classes.dialogContents}>
                <Grid container justifyContent="flex-end">
                  <Grid item style={{ position: "relative" }}>
                    <img
                      src="/images/cross.svg"
                      onClick={onClose}
                      style={{
                        cursor: "pointer",
                        position: "absolute",
                        top: "10px", // Adjust this value as needed
                        right: "10px", // Adjust this value as needed
                      }}
                    />
                  </Grid>
                </Grid>
                <Typography className={classes.modalTitle}>
                  {modalTitle}
                </Typography>
                <Typography className={classes.message}>{message}</Typography>
              </DialogContent>
              <DialogContent className={classes.dialogContent}>
                <Box className={classes.inputFieldContainer}>
                  <Box className={classes.inputContentBox}>
                    <Typography style={typography.label}>
                      Old Password
                    </Typography>
                    <CustomTextField
                      style={{ width: "100%" }}
                      placeholder="Enter your current password"
                      maxLength={64}
                      type="password"
                      borderColor="#AFB0B6"
                      backgroundColor="#fff"
                      name="oldPassword"
                      onCopy={(event)=>event.preventDefault()} // Prevent copying
                      onCut={(event)=>event.preventDefault()} // Prevent cutting
                      onPaste={(event)=>event.preventDefault()} // Prevent pasting
                      value={values.oldPassword}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.oldPassword && touched.oldPassword ? (
                      <p
                        style={{
                          color: "red",
                          marginTop: "-5px",
                          float: "left",
                        }}
                      >
                        {errors.oldPassword}
                      </p>
                    ) : null}
                  </Box>
                  <Box className={classes.inputContentBox}>
                    <Typography style={typography.label}>
                      New Password
                    </Typography>
                    <CustomTextField
                      style={{ width: "100%" }}
                      placeholder="Must be at least 8 characters"
                      maxLength={64}
                      type="password"
                      borderColor="#AFB0B6"
                      backgroundColor="#fff"
                      name="newPassword"
                      onCopy={(event)=>event.preventDefault()} // Prevent copying
                      onCut={(event)=>event.preventDefault()} // Prevent cutting
                      onPaste={(event)=>event.preventDefault()} // Prevent pasting
                      value={values.newPassword}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.newPassword && touched.newPassword ? (
                      <p
                        style={{
                          color: "red",
                          marginTop: "-5px",
                          float: "left",
                        }}
                      >
                        {errors.newPassword}
                      </p>
                    ) : null}
                  </Box>
                  <Box className={classes.inputContentBox}>
                    <Typography style={typography.label}>
                      Confirm Password
                    </Typography>
                    <CustomTextField
                      style={{ width: "100%" }}
                      placeholder="Must be at least 8 characters"
                      maxLength={64}
                      type="password"
                      borderColor="#AFB0B6"
                      backgroundColor="#fff"
                      name="confirmPassword"
                      onCopy={(event)=>event.preventDefault()} // Prevent copying
                      onCut={(event)=>event.preventDefault()} // Prevent cutting
                      onPaste={(event)=>event.preventDefault()} // Prevent pasting
                      value={values.confirmPassword}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.confirmPassword && touched.confirmPassword ? (
                      <p
                        style={{
                          color: "red",
                          marginTop: "-5px",
                          float: "left",
                        }}
                      >
                        {errors.confirmPassword}
                      </p>
                    ) : null}
                  </Box>
                </Box>
              </DialogContent>
              <DialogActions style={{ justifyContent: "center" }}>
                <Button
                  variant="contained"
                  color="secondary"
                  type="submit"
                  style={{ width: "50%" }}
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : (
                    buttonText
                  )}
                </Button>
              </DialogActions>
            </Box>
          </form>
        </Dialog>
      ) : (
        <CommonRecruiterModal
          open={isCommonRecruiterModalOpen}
          onClose={handleCommonRecruiterModalClose}
          message="Password Changed Successfully"
          buttonText="Ok"
        />
      )}
    </>
  );
};

export default ChangePassword;
