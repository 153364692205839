import { Box, Typography } from "@material-ui/core";
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { app } from "./../firebase";
import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";

import { postApiSocialHandler } from "../config/service";
import toast from "react-hot-toast";
import { AuthContext } from "../context/Auth";

const OAuth = ({ redirectUrl }) => {
  const { userLogIn } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  const auth = getAuth(app);

  const handleGoogleClick = async () => {
    console.log("clickedd to the signin with google");
    const provider = new GoogleAuthProvider();
    provider.setCustomParameters({ prompt: "select_account" });
    console.log("clickedd to the signin with google");

    try {
      console.log("clickedd to the signin with google");

      const result = await signInWithPopup(auth, provider);

      console.log(result, "---------------------------");
      const user = result.user;
      const name = result._tokenResponse;
      const idToken = await user.getIdToken();

      const data = {
        socialId: user.uid,
        firstName: name.firstName,
        lastName: name.lastName,
        email: user.email,
        profilePic: user.photoURL,
        socialType: "google",
        idToken,
      };

      setIsLoading(true);
      const response = await postApiSocialHandler("socialLogin", data);

      if (response?.responseCode === 200) {
        const token = response.result?.token;
        userLogIn(token, true);
        sessionStorage.setItem("user", JSON.stringify(response.result));
        if (token) {
          userLogIn(token, true);

          toast.success("Login successful.");
          history.push(redirectUrl || "/dashboard");
        } else {
          toast.error("Failed to retrieve login token.");
        }
      } else {
        toast.error(response?.responseMessage);
      }
    } catch (error) {
      console.log("Error while google signin", error);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Box
        style={{
          border: "1px solid grey",
          borderRadius: "10px",
          width: "100%",
          cursor: "pointer",
          height: "40px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "10px",
        }}
        onClick={handleGoogleClick}
      >
        <img
          src="images/google.png"
          alt="Google Sign In"
          style={{ height: "20px", width: "20px" }}
        />
        <Typography variant="h4">Sign in with Google</Typography>
      </Box>
    </>
  );
};

export default OAuth;
