import React, { useState, useContext } from "react";

import Footer from "../component/Footer";
import Header from "../component/header/Header";
import { Grid, CssBaseline, makeStyles, Box } from "@material-ui/core";
import { AuthContext } from "../context/Auth";
import AfterLoginHeader from "../component/header/AfterLoginheader";

const useStyles = makeStyles((theme) => ({
  sidebar: {
    boxShadow: "5px 0px #0000000D",
    backgroundColor: "#FFFFFF",
  },
  main: {
    display: "flex",
    flexDirection: "column",
    height: "100vh",
    // background: "#F2F4F7",
    overflowY: "hidden",
  },
  content: {
    width: "100%",
    // padding: theme.spacing(2),

    backgroundColor: "#F5F7FC",
    //boxShadow: "0px 0px 8px 4px rgba(0, 0, 0, 0.06)",
    marginTop: "6px",
  },
  mainContainer: {
    display: "flex",
    flexDirection: "row",
    gap: theme.spacing(1),
    backgroundColor: "#F5F7FC",
    paddingTop: "15px",
    paddingLeft: "12%",
    paddingRight: "12%",
    paddingBottom: "80px",
    overflowY: "auto",
    height: "calc(100vh - 110px)", // Adjust height to make content scrollable
    scrollbarWidth: "none", // For Firefox
    "&::-webkit-scrollbar": {
      display: "none", // For Chrome, Safari, and Opera
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "5%",
      paddingRight: "3%",
    },
  },
}));
function ProfileHomeLayout({ children }) {
  const classes = useStyles();
  const { isLogin } = useContext(AuthContext);
  return (
    <Box className={classes.main}>
      <CssBaseline />

      <Box>{isLogin ? <AfterLoginHeader /> : <Header />}</Box>

      <Box className={classes.mainContainer}>
        <Box className={classes.content}>{children}</Box>
      </Box>
    </Box>
  );
}

export default ProfileHomeLayout;
