import React from "react";

function NoDataFound() {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <img src="images/datanotfound.png" alt="NoData" />
    </div>
  );
}

export default NoDataFound;
