import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Avatar,
  List,
  Box,
  Button,
  ListItem,
  Dialog,
  DialogContent,
  ListItemIcon,
  ListItemText,
  Typography,
  IconButton,
} from "@material-ui/core";

import {
  Dashboard as DashboardIcon,
  Person as PersonIcon,
  Description as DescriptionIcon,
  Work as WorkIcon,
  Message as MessageIcon,
  Bookmark as BookmarkIcon,
  Settings as SettingsIcon,
  Help as HelpIcon,
  ExitToApp as ExitToAppIcon,
} from "@material-ui/icons";
import TimelineIcon from "@material-ui/icons/Timeline";
import { useHistory, useLocation } from "react-router-dom";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
import ChangePassword from "../../views/pages/recruiter/changePassword/ChangePassword";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import GroupIcon from "@material-ui/icons/Group";
import BookmarksIcon from "@material-ui/icons/Bookmarks";
import QuestionAnswerIcon from "@material-ui/icons/QuestionAnswer";
import ResponsiveLogoutDialog from "../modals/LogoutModal";
import DeleteJobModal from "../modals/DeleteJobModal";
import DeleteProfile from "../modals/DeleteProfile";

const useStyles = makeStyles((theme) => ({
  sidebar: {
    width: 290,
    backgroundColor: "#FFFFFF",
    boxSizing: "border-box",
    "@media(max-width:960px)": {
      display: "none",
    },
    borderTop: "2px solid #F4F4F4",
    padding: "0",
  },
  sideBarList: {
    // border: '1px solid red',
    padding: 0,
  },
  profile: {
    display: "flex",
    gap: "10px",
    backgroundImage: "url(images/profilebackground.png)", // Replace with your background image URL
    backgroundSize: "cover", // Adjust to 'contain' or 'cover' based on your preference
    backgroundPosition: "center", // Adjust to position the image as needed
    backgroundRepeat: "no-repeat",
    alignItems: "center",
    flexDirection: "column",
    paddingTop: "15px",
  },
  avatar: {
    width: "80px",
    height: "80px",
    objectFit: "contain",
    marginBottom: theme.spacing(1),
  },
  role: {
    color: "#FFFFFF",
  },
  status: {
    color: "#FFFFFF",
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(2),
    marginTop: "10px",
  },
  listItem: {
    display: "flex",
    gap: "20px",
    alignItems: "center",
    paddingLeft: "26px",
    paddingRight: "26px",
    paddingTop: "15px",
    paddingBottom: "15px",
    transition: "background-color 0.3s, color 0.3s",
    "&:hover": {
      backgroundColor: "#5E58E4",
      color: "white", // Change text color on hover
      "& $listItemIcon img": {
        filter: "brightness(0) invert(1)", // Change icon color to white on hover
      },
      "& $listItemText": {
        color: "white", // Ensure text color changes to white
      },
    },
  },
  listItemIcon: {
    height: "24px",
    width: "24px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    objectFit: "contain",
  },
  listItemText: {
    fontSize: 16,
    fontWeight: "400",
    lineHeight: "21px",
    fontFamily: "Inter",
    color: "#1E252E",
  },
  selected: {
    backgroundColor: "#5E58E4",
    color: "#FFFFFF",
    fontSize: "16px",
    "& $listItemIcon img": {
      filter: "brightness(0) invert(1)", // Change icon color for selected item
    },
    "& $listItemText": {
      color: "#FFFFFF",
      fontWeight: "500",
      fontSize: "16px",
    },
  },
  subBox: {
    display: "flex",
    flexDirection: "column", // Arrange children in a column
    alignItems: "center", // Center items horizontally
    justifyContent: "center", // Center items vertically
    padding: "15px",
    gap: "15px",
  },
  image: {
    width: "81px",
    height: "81px",
  },
  dialogheading: {
    fontSize: "28px",
    fontWeight: "600",
    color: "#161E29",
  },
  dialogsubheading: {
    lineHeight: "22px",
  },
  btnBox: {
    display: "flex",
    gap: "20px",
  },
  btn1: {
    marginTop: "10px",
    width: "200px",
    height: "52px",
  },
  btn2: {
    marginTop: "10px",
    width: "200px",
    height: "52px",
    border: "1px solid #797979",
    backgroundColor: "#797979",
    "&:hover": {
      border: "1px solid #797979",
      boxShadow: "none",
      color: "#797979",
      backgroundColor: "#fff",
    },
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[900],
  },
}));

const RecruiterSidebar = () => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation(); // Get the current location
  const [open, setOpen] = useState(false);
  const [openChangePassword, setOpenChangePassword] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const handleListItemClick = (path) => {
    history.push(path);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogOut = () => {
    history.push("/recruiterlandingpage");
  };

  const handleChangePassword = () => {
    setOpenChangePassword(true);
  };

  const handleCloseChangePassword = () => {
    setOpenChangePassword(false);
  };

  const [logoutModalOpen, setLogoutModalOpen] = useState(false); // State to manage DeleteJobModal open/close

  const handleLogout = () => {
    setLogoutModalOpen(true); // Open DeleteJobModal when "Cancel Plan" is clicked
  };
  const handleDelete = () => {
    setOpenDeleteModal(true);
  };
  const handleLogoutModalClose = () => {
    setLogoutModalOpen(false); // Close DeleteJobModal
  };
  const handleCloseModal = () => {
    setOpenDeleteModal(false); // Close the delete modal
  };

  const menuItems = [
    {
      text: "Dashboard",
      icon: "/images/DashboardIcon.svg",
      iconOnFocus: "/images/DashboardIcon.svg",
      path: "/recruiterDashboard",
    },
    {
      text: "Company Profile",
      icon: "/images/ProfileBlackIcon.svg",
      iconOnFocus: "/images/ProfileBlackIcon.svg",
      path: "/CompanyProfile",
    },
    {
      text: "Post A New Job",
      icon: "/images/PostJobBlackIcon.svg",
      iconOnFocus: "/images/PostJobBlackIcon.svg",
      path: "/postNewJob",
    },
    {
      text: "Manage Jobs",
      icon: "/images/ManageJobBlackIcon.svg",
      iconOnFocus: "/images/ManageJobBlackIcon.svg",
      path: "/ManageJob",
    },
    {
      text: "All Applicants",
      icon: "/images/ApplicantBlackIcon.svg",
      iconOnFocus: "/images/ApplicantBlackIcon.svg",
      path: "/allApplicants",
    },
    {
      text: "Shortlisted Resumes",
      icon: "/images/SavedBlackIcon.svg",
      iconOnFocus: "/images/SavedBlackIcon.svg",
      path: "/shortlisted-resume",
    },
    {
      text: "Subscriptions",
      icon: "/images/SubscriptionBlackIcon.svg",
      iconOnFocus: "/images/SubscriptionBlackIcon.svg",
      path: "/subscription",
    },
    {
      text: "Chats",
      icon: "/images/ChatBlackIcon.svg",
      iconOnFocus: "/images/ChatBlackIcon.svg",
      path: "/chats",
    },
    {
      text: "Change Password",
      icon: "/images/PasswordBlackIcon.svg",
      iconOnFocus: "/images/PasswordBlackIcon.svg",
      onClick: handleChangePassword, // Directly handle opening the modal
    },
    {
      text: "Logout",
      icon: "/images/LogoutBlackIcon.svg",
      iconOnFocus: "/images/LogoutBlackIcon.svg",
      // onClick: handleClickOpen, // Open the dialog on Logout
      onClick: handleLogout,
    },
    {
      text: "Delete Profile",
      icon: "/images/DeleteBlackIcon.svg",
      iconOnFocus: "/images/DeleteBlackIcon.svg",
      onClick: handleDelete,
    },
  ];

  return (
    <div className={classes.sidebar}>
      <List className={classes.sideBarList}>
        {menuItems.map((item, index) => (
          <ListItem
            button
            key={index}
            className={`${classes.listItem} ${
              item.path && location.pathname.startsWith(item.path)
                ? classes.selected
                : ""
            }`} // Use startsWith to check if current path includes item's path
            onClick={
              () =>
                item.path ? handleListItemClick(item.path) : item.onClick() // Fixed: Check if path exists, otherwise call item.onClick directly
            }
          >
            <ListItemIcon className={classes.listItemIcon}>
              {/* {item.icon} */}
              <img
                src={
                  location.pathname.startsWith(item.path) && item.iconOnFocus
                    ? item.iconOnFocus
                    : item.icon
                }
                alt="itemIcon"
                style={{ objectFit: "contain" }}
              />
            </ListItemIcon>
            <ListItemText
              primary={item.text}
              classes={{ primary: classes.listItemText }}
            />
          </ListItem>
        ))}
      </List>
      {/* <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <Box className={classes.mainBox}>
            <Box className={classes.subBox}>
              <img
                src="images/logout.png"
                alt="Example Image"
                className={classes.image}
              />

              <Typography variant="h3" className={classes.dialogheading}>
                Log Out
              </Typography>
              <Typography variant="h4" className={classes.dialogsubheading}>
                Are you sure, you want to log out ?
              </Typography>
              <Box className={classes.btnBox}>
                <Button
                  variant="contained"
                  color="secondary"
                  className={classes.btn2}
                  onClick={handleClose}
                >
                  No
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  className={classes.btn1}
                  onClick={handleLogOut}
                >
                  Yes
                </Button>
              </Box>
            </Box>
          </Box>
          <Box
            style={{
              position: "absolute",
              bottom: -16,
              left: "50%",
              transform: "translateX(-50%)",
              height: "16px",
              backgroundColor: "#5E58E4",
              width: "80%",
              borderBottomLeftRadius: "16px",
              borderBottomRightRadius: "16px",
            }}
          />
        </DialogContent>
      </Dialog> */}
      <ResponsiveLogoutDialog
        open={logoutModalOpen}
        onClose={handleLogoutModalClose}
      />
      <DeleteProfile
        open={openDeleteModal}
        onClose={handleCloseModal}
        Img="./images/dustbin.png"
        message="Are you sure you want to delete this profile?"
        buttonText1="No"
        buttonText2="Yes"
      />
      <ChangePassword
        open={openChangePassword}
        onClose={handleCloseChangePassword}
        message="Your new Password must be different form previous used Password."
        modalTitle="Change Password"
        buttonText="Save"
      />
    </div>
  );
};

export default RecruiterSidebar;
