import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  makeStyles,
  TextField,
  Modal,
  Typography,
} from "@material-ui/core";
import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { Formik, Form } from "formik";
import * as yup from "yup";
import { RecruiterAuthContext } from "../../../../context/recruiterAuth";
import EmailIcon from "@material-ui/icons/Email";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import CloseIcon from "@material-ui/icons/Close";
import VisibilityIcon from "@material-ui/icons/Visibility";
import Popup from "../../../../component/modals/LoginModal";
import { postDataHandler } from "../../../../config/service";
import { toast } from "react-toastify";
const useStyles = makeStyles((theme) => ({
  authContainer: {
    maxWidth: "500px",
    margin: "auto",
    display: "flex",
    flexDirection: "row",
    "@media(max-width:480px)": {
      height: "510px",
      width: "350px",
    },
    "@media(max-width:390px)": {
      height: "510px",
      width: "320px",
    },
    "@media(max-width:340px)": {
      height: "500px",
      width: "270px",
    },
  },
  formContainer: {
    borderRadius: "25px",
    backgroundColor: "white",
    padding: "48px",

    "@media(max-width:480px)": {
      borderRadius: "20px",
      padding: "20px",
    },
    position: "relative",
  },
  imageSection: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "@media(max-width:430px)": {
      display: "none",
    },
  },
  image: {
    objectFit: "cover",
    borderRadius: "0px 20px 20px 0px",
  },
  staticContent: {
    top: 210,
    width: "16%",
    position: "absolute",
    color: "white",
    padding: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      top: 155,
      width: "20%",
      marginLeft: "-60px",
    },
  },
  heading: {
    fontWeight: 700,
    lineHeight: "29px",
    fontSize: 28,
    fontFamily: "Inter",
    color: "#262626",
    "@media(max-width:1200px)": {
      fontSize: 22,
      lineHeight: "28px",
    },
    "@media(max-width:992px)": {
      fontSize: 18,
      lineHeight: "24px",
    },
    "@media(max-width:767px)": {
      fontSize: 16,
      lineHeight: "18px",
    },
  },
  heading1: {
    color: "#000000CC",
    lineHeight: "23px",
  },
  remember: {
    marginTop: "10px",
    display: "flex",
    justifyContent: "space-between",
    "@media(max-width:1024px)": {
      gap: "52px",
    },
    "@media(max-width:768px)": {
      gap: "42px",
    },
    "@media(max-width:430px)": {
      gap: "32px",
    },
    "& .MuiFormControlLabel-root": {
      marginLeft: "-12px",
    },
  },
  staticheading1: {
    fontWeight: 400,
    fontSize: 20,
    lineHeight: "24px",
    color: "#FFFFFF",
    fontFamily: "Inter",
    marginBottom: theme.spacing(1),
  },
  staticheading2: {
    fontWeight: 700,
    fontSize: 20,
    lineHeight: "24px",
    color: "#FFFFFF",
    fontFamily: "Inter",
  },
  closeButton: {
    position: "absolute",
    top: theme.spacing(-3),
    right: theme.spacing(-3),
    height: "31px",
    width: "31px",
    color: theme.palette.grey[900],
    backgroundColor: "white",
    "@media(max-width:430px)": {
      top: "-19PX",
      right: "-28PX",
    },
    "&:hover": {
      color: theme.palette.grey[900],
      backgroundColor: "white",
    },
  },
  checkbox: {
    color: "#AFAFAF",
    "&.Mui-checked": {
      color: "#5E58E4",
    },
  },
  textFieldBox: {
    marginTop: "18px",
  },
  textBox: {
    marginTop: "18px",
  },
  btnBox: {
    marginTop: "30px",
  },
}));

const RecruiterResetPassword = ({
  open,
  onClose,
  handleForgotClick,
  redirectPath,
}) => {
  const { recruiterLogIn } = useContext(RecruiterAuthContext);
  const Email = sessionStorage.getItem("email");
  const classes = useStyles();
  const history = useHistory();
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const formInitialSchema = {
    password: "",
    cpassword: "",
  };

  const formValidationSchema = yup.object().shape({
    password: yup
      .string()
      .required("Please enter your password.")
      .min(8, "Password must be at least 8 characters long."),
    cpassword: yup
      .string()
      .oneOf(
        [yup.ref("password"), null],
        "Passwords do not match. Please ensure both fields are identical."
      )
      .required("Please confirm your password."),
  });

  const handleFormSubmit = async (values) => {
    const data = {
      email: Email,
      newPassword: values.cpassword,
    };
    console.log(data);
    try {
      setIsLoading(true);
      const res = await postDataHandler("resetPassword", data);

      if (res?.responseCode === 200) {
        toast.success(res?.responseMessage);

        history.push("/recruiterDashboard");
      } else {
        toast.error(res?.data?.responseMessage);
      }
      //console.log("removed");
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Popup open={open} handleClose={onClose}>
        <Box className={classes.authContainer}>
          <Box className={classes.formContainer}>
            <Typography variant="h6" align="left" className={classes.heading}>
              Reset Password
            </Typography>
            <Box my={2}>
              <Typography variant="body2">
                To reset your password, please enter and confirm your new
                password below. Both entries must match to complete the update.
              </Typography>
            </Box>

            <Formik
              initialValues={formInitialSchema}
              validationSchema={formValidationSchema}
              onSubmit={handleFormSubmit}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                touched,
                values,
                setFieldValue,
              }) => (
                <Form onSubmit={handleSubmit}>
                  <Box className={classes.textBox}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      placeholder="Enter New Password"
                      type={showPassword ? "text" : "password"}
                      name="password"
                      id="password"
                      value={values.password}
                      onCopy={(event) => event.preventDefault()} // Prevent copying
                      onCut={(event) => event.preventDefault()} // Prevent cutting
                      onPaste={(event) => event.preventDefault()} // Prevent pasting
                      error={Boolean(touched.password && errors.password)}
                      onBlur={handleBlur}
                      onChange={(e) => {
                        const trimmedValue = e.target.value.trimStart(); // Prevent leading whitespaces
                        setFieldValue("password", trimmedValue);
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Box display="flex" alignItems="center">
                              <img src="/images/lock1.svg" alt="lock-icon" />
                              <Divider
                                orientation="vertical"
                                flexItem
                                style={{ height: "34px", margin: "0 10px" }}
                              />
                            </Box>
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() => setShowPassword(!showPassword)}
                              edge="end"
                            >
                              <Box>
                                {showPassword ? (
                                  // <HiEye className={classes.iconClass1} />
                                  <VisibilityIcon
                                    className={classes.iconClass1}
                                    style={{ width: "18px", height: "18px" }}
                                  />
                                ) : (
                                  <img
                                    src="/images/eyeP.svg"
                                    style={{
                                      width: "18px",
                                      height: "18px",
                                      objectFit: "contain",
                                    }}
                                  />
                                )}
                              </Box>
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      inputProps={{
                        maxLength: 16, // Set max length for the input
                      }}
                    />
                    <FormHelperText error>
                      {touched.password && errors.password}
                    </FormHelperText>
                  </Box>
                  <Box className={classes.textBox}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      placeholder="Confirm New Password"
                      type={showConfirmPassword ? "text" : "password"}
                      name="cpassword"
                      id="cpassword"
                      onCopy={(event) => event.preventDefault()} // Prevent copying
                      onCut={(event) => event.preventDefault()} // Prevent cutting
                      onPaste={(event) => event.preventDefault()} // Prevent pasting
                      value={values.cpassword}
                      error={Boolean(touched.cpassword && errors.cpassword)}
                      onBlur={handleBlur}
                      onChange={(e) => {
                        const trimmedValue = e.target.value.trimStart(); // Prevent leading whitespaces
                        setFieldValue("cpassword", trimmedValue);
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Box display="flex" alignItems="center">
                              <img src="/images/lock1.svg" alt="lock-icon" />
                              <Divider
                                orientation="vertical"
                                flexItem
                                style={{ height: "34px", margin: "0 10px" }}
                              />
                            </Box>
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() =>
                                setShowConfirmPassword(!showConfirmPassword)
                              }
                              edge="end"
                            >
                              <Box>
                                {showConfirmPassword ? (
                                  // <HiEye className={classes.iconClass1} />
                                  <VisibilityIcon
                                    className={classes.iconClass1}
                                    style={{ width: "18px", height: "18px" }}
                                  />
                                ) : (
                                  <img
                                    src="/images/eyeP.svg"
                                    style={{
                                      width: "18px",
                                      height: "18px",
                                      objectFit: "contain",
                                    }}
                                  />
                                )}
                              </Box>
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      inputProps={{
                        maxLength: 16, // Set max length for the input
                      }}
                    />
                    <FormHelperText error>
                      {touched.cpassword && errors.cpassword}
                    </FormHelperText>
                  </Box>

                  <Box className={classes.btnBox}>
                    <Button
                      className={classes.btn}
                      variant="contained"
                      color="secondary"
                      fullWidth
                      type="submit"
                      disabled={isLoading}
                    >
                      {isLoading ? (
                        <CircularProgress size={24} color="inherit" />
                      ) : (
                        "Submit"
                      )}
                    </Button>
                  </Box>
                </Form>
              )}
            </Formik>
          </Box>
        </Box>
      </Popup>
    </>
  );
};

export default RecruiterResetPassword;
