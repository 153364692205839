import {
  Box,
  Button,
  CircularProgress,
  Divider,
  FormHelperText,
  InputAdornment,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { Formik, Form } from "formik";
import * as yup from "yup";
import { AuthContext } from "../../../../context/Auth";
import Popup from "../../../../component/modals/LoginModal";
import RecruiterOtp from "./RecruiterOtp";
import { postDataHandler } from "../../../../config/service";
import toast from "react-hot-toast";
const useStyles = makeStyles((theme) => ({
  authContainer: {
    maxWidth: "500px",
    height: "auto",
    margin: "auto",
    display: "flex",
    flexDirection: "row",
    "@media(max-width:480px)": {
      height: "510px",
      width: "350px",
    },
    "@media(max-width:390px)": {
      height: "510px",
      width: "320px",
    },
    "@media(max-width:340px)": {
      height: "500px",
      width: "270px",
    },
  },
  formContainer: {
    height: "auto",
    borderRadius: "25px",
    backgroundColor: "white",
    padding: "48px",

    "@media(max-width:430px)": {
      borderRadius: "20px",
      padding: theme.spacing(3),
    },
    position: "relative",
  },
  heading: {
    fontWeight: 700,
    lineHeight: "29px",
    fontSize: 24,
    fontFamily: "Inter",
    color: "#262626",
    "@media(max-width:1200px)": {
      fontSize: 22,
      lineHeight: "28px",
    },
    "@media(max-width:992px)": {
      fontSize: 18,
      lineHeight: "24px",
    },
    "@media(max-width:767px)": {
      fontSize: 16,
      lineHeight: "18px",
    },
  },
  heading1: {
    lineHeight: "20px",
  },
  btn: {
    padding: "16px, 48px, 16px, 48px",
    backgroundColor: "#5E58E4",
    color: "white",
    fontWeight: "700",
  },
  textFieldBox: {
    marginTop: "15px",
  },
}));

const RecruiterForgot = ({
  open,
  onClose,
  handleOTPClick,
  handleResetClick,
}) => {
  const auth = useContext(AuthContext);
  const classes = useStyles();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [openOtp, setOpenOtp] = React.useState(false);

  const handleOtpOpen = () => {
    onClose();

    handleOTPClick();
  };
  const handleOtpClose = () => {
    setOpenOtp(false);
    sessionStorage.removeItem("otpTimeLeft");
  };
  const formInitialSchema = {
    email: "",
  };

  const formValidationSchema = yup.object().shape({
    email: yup
      .string()
      .email("Please enter a valid email address.")
      .max(320, "Email should not exceed 320 characters.")
      .required("Please enter your email."),
  });

  const handleFormSubmit = async (values) => {
    const data = {
      email: values.email.trim().toLowerCase(),
    };
    console.log(data, "data");
    try {
      setIsLoading(true);

      const res = await postDataHandler("resendOTP", data);

      if (res?.responseCode === 200) {
        toast.success(res?.responseMessage);

        //  sessionStorage.setItem("otpTimeLeft", 180);
        sessionStorage.setItem("email", data.email);
        handleOtpOpen(true);

        setIsLoading(false);
      } else {
        toast.error(res?.data?.responseMessage);

        setIsLoading(false);
      }
      //console.log("removed");
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Popup open={open} handleClose={onClose}>
        <Box className={classes.authContainer}>
          <Box className={classes.formContainer}>
            <Box style={{}}>
              <Typography
                variant="body6"
                align="left"
                className={classes.heading}
              >
                Forgot Password
              </Typography>
              <Box my={1.4}>
                <Typography variant="h6" className={classes.heading1}>
                  Enter your email for the verification process. We will send a
                  six digit code to your email.
                </Typography>
              </Box>

              <Formik
                initialValues={formInitialSchema}
                validationSchema={formValidationSchema}
                onSubmit={(values) => handleFormSubmit(values)}
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  touched,
                  values,
                }) => (
                  <Form>
                    <Box className={classes.textFieldBox}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        placeholder="Enter email address"
                        name="email"
                        id="email"
                        inputProps={{ maxLength: 320 }}
                        value={values.email}
                        error={Boolean(touched.email && errors.email)}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        onInput={(e) => {
                          let value = e.target.value;
                          value = value.replace(/\s/g, "").toLowerCase();
                          e.target.value = value;
                          window.localStorage.setItem("email", e.target.value);
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Box display="flex" alignItems="center">
                                <img src="/images/email.svg" />
                                <Divider
                                  orientation="vertical"
                                  flexItem
                                  style={{ height: "34px", margin: "0 10px" }}
                                />
                              </Box>
                            </InputAdornment>
                          ),
                        }}
                      />

                      <FormHelperText error>
                        {touched.email && errors.email}
                      </FormHelperText>
                    </Box>

                    <Box style={{ marginTop: "25px" }}>
                      <Button
                        className={classes.btn}
                        variant="contained"
                        color="secondary"
                        fullWidth
                        type="submit"
                        disabled={isLoading}
                      >
                        Continue
                        {isLoading && (
                          <CircularProgress
                            size={20}
                            style={{ color: "#fff", marginLeft: "10px" }}
                          />
                        )}
                      </Button>
                    </Box>
                  </Form>
                )}
              </Formik>
            </Box>
          </Box>
        </Box>
      </Popup>
      <RecruiterOtp open={openOtp} onClose={handleOtpClose} />
    </>
  );
};

export default RecruiterForgot;
