import React from "react";
import { Grid, CssBaseline, makeStyles, Box } from "@material-ui/core";
import RecruiterLoginHeader from "../component/recruiterHeader/RecruiterLoginHeader";
import RecruiterSidebar from "../component/recruiterSidebar/RecruiterSidebar";

const useStyles = makeStyles((theme) => ({
  mainBox: {
    display: "flex",
    flexDirection: "column",
    height: "100vh",
    background: "#F2F4F7",
    overflow: "hidden",
  },
  sidebar: {
    boxShadow: "5px 0px #0000000D",
    backgroundColor: "#FFFFFF",
    //height: '100vh', // Full height for the sidebar
    //position: 'sticky', // Make the sidebar sticky
    top: 0,
    height: "calc(100vh - 104px)",
    overflowY: "auto",
    scrollbarWidth: "none", // For Firefox
    "&::-webkit-scrollbar": {
      display: "none", // For Chrome, Safari, and Opera
    },
  },
  content: {
    //width: "calc(100vw - 500px)",
    flex: 1,
    backgroundColor: "#F5F7FC",
    boxShadow: "0px 0px 8px 4px rgba(0, 0, 0, 0.06)",
    marginTop: "6px",
    overflowY: "auto", // Enables vertical scrolling
    height: "calc(100vh - 104px)", // Adjust height to make content scrollable
    scrollbarWidth: "none", // For Firefox
    "&::-webkit-scrollbar": {
      display: "none", // For Chrome, Safari, and Opera
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  mainContainer: {
    display: "flex",
    flexDirection: "row",
    gap: theme.spacing(1),
    backgroundColor: "#F5F7FC",
  },
}));


function RecruiterDashboardLayout({ children }) {
  const classes = useStyles();

  return (
    <Box className={classes.mainBox}>
      <CssBaseline />
      <Box >
        <RecruiterLoginHeader />
      </Box>
      <Box className={classes.mainContainer}>
        <Box className={classes.sidebar}>
          <RecruiterSidebar />
        </Box>
        <Box className={classes.content}>{children}</Box>
      </Box>
    </Box>
  );
}

export default RecruiterDashboardLayout;