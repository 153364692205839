import React, { useContext } from "react";
import { Route, useHistory } from "react-router-dom";
import { AuthContext } from "../context/Auth";
import { RecruiterAuthContext } from "../context/recruiterAuth";


const AuthGuard = ({ component: Component, ...rest }) => {
 const { isLogin } = useContext(AuthContext);
  const { recruiterLoggedIn } = useContext(RecruiterAuthContext);

  const history = useHistory();

  

  if (!recruiterLoggedIn) {
    history.push("/recruiterlandingpage"); // Change this logic as needed
    return null;
  }


if (isLogin) {
    history.push("/"); // Change this logic as needed
    return null; // Redirect to a suitable page for seekers
  }

  return <Route {...rest} render={(props) => <Component {...props} />} />;
};

export default AuthGuard;
