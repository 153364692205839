import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  makeStyles,
} from "@material-ui/core";
import typography from "../../theme/typography";
import CloseJobImg from "../../assets/CloseJob.png";
import CommonRecruiterModal from "./CommonRecruiterModal";
import { deleteDataHandlerWithToken } from "../../config/service";

const useStyles = makeStyles(() => ({
  noHover: {
    "&:hover": {
      backgroundColor: "rgba(91, 90, 91, 1)",
      boxShadow: "none",
      color: "white",
    },
  },
}));

const DeleteJobModal = ({ open, onClose, CancelClose, job }) => {
  const [isCommonRecruiterModalOpen, setIsCommonRecruiterModalOpen] =
    useState(false);

  // Determine the message and button text based on job status
  const isJobClosed = job.status === "CLOSED";
  const message = isJobClosed
    ? "Are you sure you want to open this job?"
    : "Are you sure you want to close this job?";
  const buttonText2 = isJobClosed ? "Yes, Open" : "Yes, Close";

  const handleToggleJobPost = async () => {
    const query = {
      id: job.jobId,
    };
    const res = await deleteDataHandlerWithToken("deleteJob", query);
    if (res?.responseCode === 200) {
      setIsCommonRecruiterModalOpen(true);
    }
  };

  const handleNoButtonClick = () => {
    CancelClose();
  };

  const handleCommonRecruiterModalClose = () => {
    setIsCommonRecruiterModalOpen(false);
    CancelClose();
  };

  const handleRecruiterModalClose = () => {
    setIsCommonRecruiterModalOpen(false);
    onClose();
  };

  const classes = useStyles();

  return (
    <>
      {!isCommonRecruiterModalOpen ? (
        <Dialog open={open} onClose={CancelClose} maxWidth="sm">
          <Box style={{ padding: "20px 14%" }}>
            <DialogContent
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                textAlign: "center",
                width: "100%",
              }}
            >
              <img
                src={CloseJobImg}
                alt="Modal"
                style={{
                  width: "auto",
                  height: "100%",
                  objectFit: "contain",
                  marginBottom: "16px",
                }}
              />
              <Typography style={{ ...typography.modalMessage }}>
                {message}
              </Typography>
            </DialogContent>
            <DialogActions style={{ justifyContent: "center" }}>
              <Button
                variant="contained"
                color="secondary"
                style={{
                  backgroundColor: "rgba(91, 90, 91, 1)",
                  border: "none",
                }}
                onClick={handleNoButtonClick}
                classes={{ root: classes.noHover }}
                fullWidth
              >
                No
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleToggleJobPost}
                fullWidth
              >
                {buttonText2}
              </Button>
            </DialogActions>
          </Box>
          <Box
            style={{
              position: "absolute",
              bottom: -16,
              left: "50%",
              transform: "translateX(-50%)",
              height: "16px",
              backgroundColor: "#5E58E4",
              width: "80%",
              borderBottomLeftRadius: "16px",
              borderBottomRightRadius: "16px",
            }}
          />
        </Dialog>
      ) : (
        <CommonRecruiterModal
          open={isCommonRecruiterModalOpen}
          onClose={handleRecruiterModalClose}
          message={`Job posting was ${
            isJobClosed ? "Opened" : "Closed"
          } Successfully!`}
          buttonText="Ok"
        />
      )}
    </>
  );
};

export default DeleteJobModal;
