import React, { useContext, useEffect, useState } from "react";
import {
  Toolbar,
  IconButton,
  Typography,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Box,
  Avatar,
  Popover,
  ClickAwayListener,
  Divider,
  Link,
  CircularProgress,
  ListItemIcon,
  Button,
  Dialog,
  DialogContent,
  LinearProgress,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import NotificationsNoneIcon from "@material-ui/icons/NotificationsNone";
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useHistory, useLocation } from "react-router-dom";
import MegaMenu from "../MegaMenu";
import CloseIcon from "@material-ui/icons/Close";
import Logo from "../../assets/logoipsum.svg";
import SeekerLogout from "../modals/SeekerLogout";
import NoNotificationImg from "../../assets/NoNotificationImg.png";

import {
  getApiHandlerWithToken,
  postApiHandlerWithToken,
  putApiHandlerWithToken,
  putDataHandlerWithToken,
} from "../../config/service";
import toast from "react-hot-toast";
import { format, formatDistanceToNow, parseISO } from "date-fns";
import { ProfileContext } from "../../context/ProfileContext";
import { AuthContext } from "../../context/Auth";
import NotificationBell from "../recruiterHeader/NotificationCount";

const useStyles = makeStyles((theme) => ({
  selected: {
    backgroundColor: "#5E58E41A",
    display: "flex",
    alignItems: "center",

    gap: "20px",
    padding: "20px",
    paddingLeft: "30px",
    "& $listItemText": {
      color: "#5E58E4",
      fontWeight: "500",
    },
    "& $listItemIcon": {
      color: "#5E58E4",
    },
  },
  listItemText: {
    fontSize: 16,
    fontWeight: "400",
    lineHeight: "19px",
    fontFamily: "Inter",
    color: "#1E252E",
    "&:hover": {
      color: "#5E58E4",
    },
  },
  listItem: {
    display: "flex",
    alignItems: "center",

    gap: "20px",
    padding: "19px",
    paddingLeft: "30px",
    transition: "background-color 0.3s",
    transition: "background-color 0.3s, color 0.3s", // Add color transition
    "&:hover": {
      backgroundColor: "#5E58E41A", // Light green background on hover
      color: "#5E58E4", // Text color green on hover
      "& $listItemIcon, & $listItemText": {
        color: "#5E58E4", // Ensure both text and icon are green on hover
      },
    },
  },
  popoverContent: {
    // width: "170px",
    height: "auto",
    boxSizing: "border-box",
  },
  iconAndName: {
    display: "flex",
    alignItems: "center",
    gap: "16px",
    padding: "5px 20px 5px 20px",

    cursor: "pointer",
    "& p": {
      lineHeight: "35px",
      whiteSpace: "nowrap",
    },
    "&:hover": {
      backgroundColor: "#f0f0f0",

      transition: "background-color 0.3s ease",
    },
  },
  popoverPaper: {
    borderRadius: "10px", // Add your desired borderRadius here
  },
  listItemIcon: {
    height: "20px",
    width: "20px",
    objectFit: "contain",
    minWidth: 20,
    "&:hover": {
      color: "#5E58E4",
    },
  },
  menuItems: {
    cursor: "pointer",
    display: "flex",
    justifyContent: "flex-end",
    gap: "32px",
    alignItems: "center",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "flex-start",
      gap: "15px",
    },
  },
  menuBtn: {
    display: "flex",
    justifyContent: "flex-end",
    gap: "32px",
    alignItems: "center",
  },
  drawerList: {
    width: 250,
    height: "100%",
  },
  drawerListItem: {
    padding: "16px 24px",
    borderBottom: `1px solid ${theme.palette.divider}`,
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
  },
  drawerListItemText: {
    fontSize: "16px",
    color: theme.palette.text.primary,
  },
  drawerHeader: {
    padding: "16px 24px",
    fontSize: "20px",
    fontWeight: "bold",
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  navbar: {
    padding: "1.2rem",

    background: "#FFFFFF",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.8)", // Light shadow for elevation
    // // Ensures it stays above other elements
    zIndex: 1200,
  },
  toolbar: {
    paddingLeft: "0px",
    paddingRight: "0px",
  },
  iconColor: {
    color: "#000000CC",
    opacity: "0.9",
  },
  logo: {
    height: "60px",
    width: "60px",
    border: "1px solid #5E58E4",
    borderRadius: "50px",
    background:
      "radial-gradient(50% 50% at 50% 50%,  #5E58E4 50%, #5E58E4 100%)",
    color: "white",
    marginRight: "20px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },
  avatar: {
    width: "55px",
    height: "55px",
    objectFit: "contain",
  },
  notification: {
    width: "24px",
    height: "28px",
  },
  noificationBox: {
    width: "50px",
    height: "50px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    border: "1px solid #5E58E4",
    backgroundColor: "#5E58E420",
    borderRadius: "10px",
    padding: "5px",
    // paddingRight: "3px",
  },
  notificationicon: {
    width: "20px",
    height: "24px",
    objectFit: "contain",
  },
  flexContainer: {
    padding: "20px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  notificationPopup: {
    width: "100%",
  },
  heading: {
    fontWeight: 700,
  },
  heading1: {
    fontWeight: 500,
    color: "#5E58E4",
  },
  notificationBox: {
    width: "100%",
    marginBottom: "5px",
    padding: "10px 20px 10px 20px",
    position: "relative",
    // display: 'flex',
    // justifyContent: 'space-between',
    // alignItems: 'center',
    backgroundColor: "#F5F7FC",
  },
  closeIcon: {
    height: "15px",
    width: "15px",
    position: "absolute",
    top: "15px",
    right: "15px",
  },
  footer: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    marginTop: theme.spacing(1),
  },
  caption: {
    fontSize: "10px",
    fontWeight: 400,
    lineHeight: "12.1px",
  },
  noNotificationsContainer: {
    // Add style for no notifications container
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
    textAlign: "center",
    justifyContent: "center",
  },
  noNotificationsImage: {
    // Add style for no notifications image
    width: "auto",
    height: "100%",
    objectFit: "contain",
    marginBottom: "10px",
  },
  noNotificationsText: {
    // Add style for no notifications text
    fontSize: "16px",
    color: theme.palette.text.secondary,
  },
  activeTab: {
    color: "#5E58E4", // Green color for active tab
  },
  profile: {
    display: "flex",
    gap: "0px",
    backgroundImage: "url(images/profilebackground.png)",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    alignItems: "center",
    flexDirection: "column",
    paddingTop: "15px",

    position: "sticky",
    top: 0, // This keeps the profile fixed at the top
    zIndex: 10, // Ensure it stays above the list
    padding: theme.spacing(2),
    backgroundColor: "#fff", // Add background to avoid overlap with list items
  },
  avatar: {
    border: " 1px solid #FFFFFF",
    width: "50px",
    height: "50px",
    objectFit: "contain",
    marginBottom: theme.spacing(1),
  },
  contentBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    gap: "5px",
  },
  progressBarBox: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    borderRadius: "none",
  },
  progressBar: {
    height: "10px",
    width: "100%",
    borderRadius: "10px",

    border: "1px solid white",
    "& .MuiLinearProgress-bar": {
      backgroundColor: "White", // For the filled portion
    },
    "& .MuiLinearProgress-colorPrimary": {
      backgroundColor: "White", // For the filled portion (alternative way)
    },
    "& .MuiLinearProgress-root": {
      borderRadius: "5px",
      height: "100%",
    },
  },
  status: {
    width: "100%",
    paddingLeft: "15%",
    paddingRight: "15%",
    color: "#FFFFFF",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    gap: "5px",
    marginBottom: theme.spacing(1),
    marginTop: "2px",
  },
  success: {
    fontWeight: 700,
    fontSize: 18,
    lineHeight: "21px",
    fontFamily: "Inter",
    color: "#FFFFFF",
    marginLeft: theme.spacing(1),
  },
}));

const AfterLoginHeader = () => {
  const classes = useStyles();
  const location = useLocation();
  const [drawerOpen, setDrawerOpen] = useState(false);

  const [isMegaMenuOpen, setIsMegaMenuOpen] = useState(false);
  const [notificationAnchorEl, setNotificationAnchorEl] = useState(null);
  const [logoutDialogOpen, setLogoutDialogOpen] = useState(false);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const handleItemClick = (path) => {
    history.push(path);
  };
  const {
    profile,
    getProfile,
    avatarSrc,
    setAvatarSrc,
    notifications,
    setNotifications,
    loading,
  } = useContext(ProfileContext);

  const user = sessionStorage.getItem("user");
  let token = null;
  let userEmail = null;
  if (user) {
    try {
      const parsedUser = JSON.parse(user);
      token = parsedUser?.token || null; // Safely access the token if it exists
      userEmail = parsedUser?.email || null;
    } catch (error) {
      console.error("Error parsing user from sessionStorage:", error);
    }
  } else {
    console.warn("No user found in sessionStorage");
  }
  const history = useHistory();

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const toggleMegaMenu = () => {
    setIsMegaMenuOpen(!isMegaMenuOpen);
  };

  const isMobile = useMediaQuery("(max-width:961px)");
  const truncateText = (text, limit = 30) => {
    return text && text.length > limit ? text.slice(0, limit) + "..." : text;
  };
  const truncateName = (text, limit = 20) => {
    return text && text.length > limit ? text.slice(0, limit) + "..." : text;
  };

  const handleClick = () => {
    history.push("/profile");
  };
  const handleNavigation = (text) => {
    switch (text) {
      case "Dashboard":
        history.push("/dashboard");
        break;

      case "Find Jobs":
        history.push("/job-search");
        break;

      case "Blogs":
        history.push("/blog");
        break;
      case "My Profile":
        history.push("/myprofile");
        break;
      case "My Resume":
        history.push("/myresume");
        break;
      case "Applied Job":
        history.push("/appliedjobs");
        break;
      case "Messages":
        history.push("/message");
        break;
      case "Saved jobs":
        history.push("/whitelistedJobs");
        break;
      case "Settings":
        history.push("/setting");
        break;
      case "Help & Complaint":
        history.push("/helpandcomplaint");
        break;
      case "Analytics":
        history.push("/profileanalytics");
        break;
      case "Logout":
        setLogoutDialogOpen(true);
        break;
      default:
        break;
    }
  };
  const getActiveTabClass = (path) => {
    switch (path) {
      case "/dashboard":
        return "Dashboard";
      case "/job-search":
        return "Find Jobs";
      case "/blog":
        return "Blog";
      default:
        return "";
    }
  };

  const handleNotificationClick = (event) => {
    setNotificationAnchorEl(event.currentTarget);
  };

  const handleCloseNotification = () => {
    setNotificationAnchorEl(null);
  };

  const open = Boolean(notificationAnchorEl);

  // Function to handle the delete action
  const handleRead = async (notificationId) => {
    const user = JSON.parse(sessionStorage.getItem("user"));
    const userId = user?.id;

    try {
      const data = {
        userId: userId, // Include the userId
        notificationIds: [notificationId], // Array of notification IDs
        status: "READ", // Pass the status as a string
      };

      const response = await putDataHandlerWithToken(
        "updateNotificationStatus",
        data
      );

      if (response.responseCode === 200) {
        console.log("Notifications marked as read:", response.result);
        fetchNotifications(); // Refresh notifications after marking as read
      } else {
        console.error("Failed to mark notifications as read");
      }
    } catch (error) {
      console.error("Error marking notifications as read:", error);
    }
  };

  const handleMarkAllRead = async () => {
    const user = JSON.parse(sessionStorage.getItem("user"));
    const userId = user?.id;

    const notificationIds = notifications.map(
      (notification) => notification._id
    );

    const requestBody = {
      userId: userId,
      notificationIds: notificationIds,
      status: "READ",
    };

    try {
      const response = await putDataHandlerWithToken(
        "updateNotificationStatus",
        requestBody
      );

      if (response.responseCode === 200) {
        console.log("All notifications marked as read:", response.result);
        fetchNotifications(); // Refresh notifications after marking all as read
      } else {
        console.error("Failed to mark all notifications as read");
      }
    } catch (error) {
      console.error("Error marking all notifications as read:", error);
    }
  };

  useEffect(() => {
    fetchNotifications();
  }, []);

  const fetchNotifications = async () => {
    const user = JSON.parse(sessionStorage.getItem("user"));
    const userId = user?.id;
    try {
      const response = await getApiHandlerWithToken("getAllNotifications", {
        userId,
      });

      if (response.responseCode === 200) {
        const reversedNotifications = response?.result?.notifications.reverse();
        setNotifications(reversedNotifications);
      } else {
        console.log("Something went wrong");
      }
    } catch (error) {
      console.error("Error fetching notifications:", error);
    }
  };
  const handleLogout = () => {
    setLogoutDialogOpen(true); // Open DeleteJobModal when "Cancel Plan" is clicked
  };

  const drawerList = (
    <Box
      className={classes.drawerList}
      role="presentation"
      onClick={handleDrawerToggle}
      onKeyDown={handleDrawerToggle}
    >
      <div className={classes.profile}>
        {loading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="17vh"
          >
            <CircularProgress size={30} style={{ color: "#fff" }} />
          </Box>
        ) : (
          <>
            <Avatar className={classes.avatar} src={avatarSrc} alt="profile" />
            <Box className={classes.contentBox}>
              <Typography variant="h3" className={classes.role}>
                {truncateName(
                  `${profile?.firstName || ""} ${
                    profile?.lastName || ""
                  }`.trim() || "--"
                )}
              </Typography>
              <Typography variant="h6" className={classes.role1}>
                {truncateText(profile?.experience?.[0]?.jobTitle)}
              </Typography>
              <Typography variant="h6" className={classes.role1}>
                {truncateText(profile?.experience?.[0]?.companyName)}
              </Typography>
            </Box>
            <Typography variant="h6" className={classes.status}>
              {profile?.isComplete > 0 ? (
                <Box className={classes.progressBarBox}>
                  <Box className={classes.progressBar}>
                    <LinearProgress
                      variant="determinate"
                      value={Math.min(profile?.isComplete, 100)}
                      style={{
                        width: `${Math.min(profile?.isComplete, 100)}%`,
                      }}
                    />
                  </Box>

                  <span className={classes.success}>
                    {Math.min(profile?.isComplete, 100)}%{" "}
                  </span>
                </Box>
              ) : (
                <Button
                  onClick={handleClick}
                  style={{
                    padding: "13px 20px 13px 20px",
                    borderRadius: "10px",
                    background: "white",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)", // Add box shadow
                  }}
                >
                  <Typography
                    style={{
                      fontFamily: "Inter",
                      fontSize: "12px",
                      fontWeight: 500,
                      lineHeight: "12.1px",
                      textAlign: "center", // Center text inside Typography (optional here)
                    }}
                  >
                    Update Profile
                  </Typography>
                </Button>
              )}
              {/* <Typography
            variant="h6"
            style={{ fontSize: "10px", lineHeight: "12px", color: "#FFFFFF" }}
          >
            Last Updated Yesterday
          </Typography> */}
            </Typography>
          </>
        )}
      </div>

      <List>
        {[
          {
            text: "Dashboard",
            imgSrc: "images/dashboardicon.png",
            imgHover: "images/dashboardgreen.png",
            imgActive: "images/dashboardgreen.png",
            path: "/dashboard",
          },
          {
            text: "My Profile",
            imgSrc: "images/myprofileicon.png",
            imgHover: "images/myprofilegreen.png",
            imgActive: "images/myprofilegreen.png",
            path: "/myprofile",
          },
          {
            text: "My Resume",
            imgSrc: "images/myresume.png",
            imgHover: "images/myresumegreen.png",
            imgActive: "images/myresumegreen.png",
            path: "/myresume",
          },
          {
            text: "Applied Jobs",
            imgSrc: "images/appliedjobsidebar.png",
            imgHover: "images/appliedjosgreen.png",
            imgActive: "images/appliedjosgreen.png",
            path: "/appliedjobs",
          },
          {
            text: "Messages",
            imgSrc: "images/messageiconsidebar.png",
            imgHover: "images/messagegreen.png",
            imgActive: "images/messagegreen.png",
            path: "/message",
          },
          {
            text: "Saved jobs",
            imgSrc: "images/saved.png",
            imgHover: "images/savedjobsgreen.png",
            imgActive: "images/savedjobsgreen.png",
            path: "/whitelistedJobs",
          },
          {
            text: "Settings",
            imgSrc: "images/setting.png",
            imgHover: "images/settingscreen.png",
            imgActive: "images/settingscreen.png",
            path: "/setting",
          },
          {
            text: "Help & Complaint",
            imgSrc: "images/helpandcomplain.png",
            imgHover: "images/helpandcomplaingreen.png",
            imgActive: "images/helpandcomplaingreen.png",
            path: "/helpandcomplaint",
          },
          {
            text: "Analytics",
            imgSrc: "images/analytics.png",
            imgHover: "images/analyticsgreen.png",
            imgActive: "images/analyticsgreen.png",
            path: "/profileanalytics",
          },
          ...(token
            ? [
                {
                  text: "Logout",
                  imgSrc: "images/logoutsidebar.png",
                  imgHover: "images/logoutgreen.png",
                  imgActive: "images/logoutgreen.png",
                  onClick: handleLogout,
                },
              ]
            : []),
        ].map((item, index) => (
          <ListItem
            button
            key={index}
            className={
              location.pathname === item.path
                ? classes.selected
                : classes.listItem
            }
            onClick={() =>
              item.path
                ? handleItemClick(item.path)
                : item.onClick && item.onClick()
            }
            onMouseEnter={() => setHoveredIndex(index)} // Set hover index on mouse enter
            onMouseLeave={() => setHoveredIndex(null)}
          >
            <ListItemIcon className={classes.listItemIcon}>
              <img
                src={
                  location.pathname === item.path
                    ? item.imgActive
                    : hoveredIndex === index
                    ? item.imgHover
                    : item.imgSrc
                }
                alt={item.text}
                style={{
                  width: "24px",
                  height: "24px",
                  transition: "transform 0.3s ease",
                  transform: hoveredIndex === index ? "scale(1.1)" : "scale(1)", // Slight zoom on hover
                }}
              />
            </ListItemIcon>
            <ListItemText classes={{ primary: classes.listItemText }}>
              {item.text}
            </ListItemText>
          </ListItem>
        ))}

        {/* Conditionally render Logout button if token is present (user is logged in) */}
        {/* {token && (
          <ListItem
            button
            className={classes.drawerListItem}
            onClick={() => handleNavigation("Logout")}
          >
            <ListItemText
              primary="Logout"
              classes={{ primary: classes.drawerListItemText }}
            />
          </ListItem>
        )} */}
      </List>
    </Box>
  );
  const { userLogout } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const handleNotificationClickOpen = () => {
    history.push("/notification"); // Change '/home' to your home route
  };

  const handleLogoClick = () => {
    history.push("/");
  };
  const handleNavigateToProfile = () => {
    history.push("/myprofile");
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    console.log("Koca: handlePopoverClose is calling... ");
    setAnchorEl(null);
  };
  const handleLogOut = async () => {
    setIsLoading(true);
    try {
      await userLogout();
      history.push("/");
      setAnchorEl(null);
    } catch (error) {
      console.error("Error during logout:", error);
    } finally {
      setTimeout(() => {
        setIsLoading(false);
        history.push("/");
      }, 500);
    }
  };
  const isPopoverOpen = Boolean(anchorEl);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (anchorEl && !anchorEl.contains(event.target)) {
        handlePopoverClose();
      }
    };
    if (isPopoverOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [anchorEl, isPopoverOpen]);

  useEffect(() => {
    getProfile();
  }, []);

  useEffect(() => {
    setAvatarSrc(profile?.profilePicture);
  }, [profile]);

  return (
    <>
      <Box className={classes.navbar}>
        <Toolbar className={classes.toolbar}>
          {isMobile && (
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              width="100%"
              padding="10px"
              overflow="hidden"
            >
              {/* Menu Icon */}
              <IconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={handleDrawerToggle}
                // Adjust left margin
              >
                <MenuIcon />
              </IconButton>

              {/* Logo */}
              <Box
                onClick={handleLogoClick}
                style={{
                  cursor: "pointer",
                  flexGrow: 1,
                  display: "flex",
                  justifyContent: "center",
                  overflow: "hidden", // Prevent overflow for small screens
                }}
              >
                <img
                  src={Logo}
                  alt="logo"
                  style={{
                    width: "auto",
                    height: "30px", // Reduced size of the logo
                    maxWidth: "100%",
                    maxHeight: "30px", // Limit height to prevent overflow
                    objectFit: "contain",
                  }}
                />
              </Box>

              {/* Notification Bell */}
              <Box
                className={classes.noificationBox}
                onClick={handleNotificationClick}
                style={{
                  marginRight: "-10px",
                  maxWidth: "50px", // Set max width for bell icon
                  maxHeight: "45px", // Set max height for bell icon
                  overflow: "hidden", // Prevent overflow
                }}
              >
                <ClickAwayListener onClickAway={handleCloseNotification}>
                  <div>
                    <Box
                      className={classes.noificationBox}
                      onClick={handleNotificationClick}
                    >
                      <NotificationBell
                        notificationCount={
                          notifications.filter(
                            (notification) =>
                              notification.user[0]?.status === "NEW"
                          ).length
                        }
                      />
                    </Box>

                    {/* Notification Popover for mobile */}

                    <ClickAwayListener onClickAway={handleCloseNotification}>
                      <Popover
                        open={open}
                        anchorEl={notificationAnchorEl}
                        onClose={handleCloseNotification}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "center",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "center",
                        }}
                        PaperProps={{
                          className: classes.popoverPaper, // Apply additional styling here
                          style: {
                            maxWidth: "300px", // Set a max width for mobile screens
                            width: "100%", // Ensure it adjusts to the available width
                            margin: "10px", // Add margin to prevent edge clipping
                          },
                        }}
                      >
                        <Box my={0} className={classes.flexContainer}>
                          <Typography variant="h4" className={classes.heading}>
                            Notification
                          </Typography>

                          {/* Display "Mark as read" if there are any NEW notifications */}
                          {notifications.some(
                            (notification) =>
                              notification.user[0]?.status === "NEW"
                          ) && (
                            <Typography
                              variant="h6"
                              className={classes.heading1}
                              onClick={handleMarkAllRead}
                              style={{ cursor: "pointer" }}
                            >
                              Mark as read
                            </Typography>
                          )}
                        </Box>
                        <div
                          className={classes.notificationPopup}
                          style={{
                            maxHeight: "300px", // Limit height to ensure it fits on small screens
                            overflowY: "auto", // Enable scrolling if content exceeds height
                          }}
                        >
                          {notifications.filter(
                            (notification) =>
                              notification.user[0]?.status === "NEW"
                          ).length > 0 ? (
                            // Map through NEW notifications
                            notifications
                              .filter(
                                (notification) =>
                                  notification.user[0]?.status === "NEW"
                              )
                              .slice(0, 3) // Limit to the first 3 NEW notifications
                              .map((notification) => {
                                const createdAt = parseISO(
                                  notification.createdAt
                                );
                                const formattedDate = format(
                                  createdAt,
                                  "EEEE, MMM. dd yyyy 'at' hh:mm a"
                                );
                                const timeAgo = formatDistanceToNow(createdAt, {
                                  addSuffix: true,
                                });

                                return (
                                  <Box
                                    key={notification._id}
                                    className={classes.notificationBox}
                                  >
                                    <Box>
                                      <Typography
                                        variant="h6"
                                        style={{
                                          maxWidth: "calc(100% - 40px)", // Prevent overflowing content
                                          fontWeight: 500,
                                        }}
                                      >
                                        {notification.message}
                                      </Typography>
                                      <div className={classes.footer}>
                                        <Typography
                                          className={classes.caption}
                                          variant="caption"
                                        >
                                          {formattedDate}
                                        </Typography>
                                        <Typography
                                          className={classes.caption}
                                          variant="caption"
                                        >
                                          {timeAgo}
                                        </Typography>
                                      </div>
                                    </Box>

                                    <IconButton
                                      className={classes.closeIcon}
                                      onClick={() =>
                                        handleRead(notification._id)
                                      }
                                    >
                                      <CloseIcon
                                        style={{
                                          fontSize: "0.9rem",
                                          color: "black",
                                        }}
                                      />
                                    </IconButton>
                                  </Box>
                                );
                              })
                          ) : (
                            <Box className={classes.noNotificationsContainer}>
                              <img
                                src={NoNotificationImg}
                                alt="No notifications"
                                className={classes.noNotificationsImage}
                              />
                              <Typography
                                className={classes.noNotificationsText}
                              >
                                No notifications
                              </Typography>
                            </Box>
                          )}
                        </div>
                      </Popover>
                    </ClickAwayListener>
                  </div>
                </ClickAwayListener>
              </Box>
            </Box>
          )}

          {!isMobile && (
            <>
              <Box onClick={handleLogoClick} style={{ cursor: "pointer" }}>
                <img
                  src={Logo}
                  alt="logo"
                  style={{ width: "auto", height: "70px" }}
                />
              </Box>

              <Box className={classes.menuItems} flexGrow={1}>
                {["Dashboard", "Find Jobs", "Blogs"].map((text) => (
                  <Box
                    display="flex"
                    alignItems="center"
                    key={text}
                    onClick={() => handleNavigation(text)}
                    sx={{ cursor: "pointer" }}
                  >
                    <Typography
                      variant="h4"
                      display="inline"
                      color="#000000CC"
                      className={
                        getActiveTabClass(location.pathname) === text
                          ? classes.activeTab
                          : ""
                      }
                    >
                      {text}
                    </Typography>
                    {text === "Browse Jobs" &&
                      (isMegaMenuOpen ? (
                        <ExpandLessIcon className={classes.iconColor} />
                      ) : (
                        <ExpandMoreIcon className={classes.iconColor} />
                      ))}
                  </Box>
                ))}
                <Box className={classes.menuBtn}>
                  <ClickAwayListener onClickAway={handleCloseNotification}>
                    <div>
                      <Box
                        className={classes.noificationBox}
                        onClick={handleNotificationClick}
                      >
                        <NotificationBell
                          notificationCount={
                            notifications.filter(
                              (notification) =>
                                notification.user[0]?.status === "NEW"
                            ).length
                          }
                        />
                      </Box>
                      <Popover
                        open={open}
                        anchorEl={notificationAnchorEl}
                        onClose={handleCloseNotification}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "center",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "center",
                        }}
                        PaperProps={{
                          className: classes.popoverPaper, // Apply the borderRadius here
                        }}
                      >
                        <Box my={0} className={classes.flexContainer}>
                          <Typography variant="h4" className={classes.heading}>
                            Notification
                          </Typography>

                          {/* Display "Mark as read" if there are any NEW notifications */}
                          {notifications.some(
                            (notification) =>
                              notification.user[0]?.status === "NEW"
                          ) && (
                            <Typography
                              variant="h6"
                              className={classes.heading1}
                              onClick={handleMarkAllRead}
                              style={{ cursor: "pointer" }}
                            >
                              Mark as read
                            </Typography>
                          )}
                        </Box>
                        <div
                          className={classes.notificationPopup}
                          style={{ width: "380px" }}
                        >
                          {notifications.filter(
                            (notification) =>
                              notification.user[0]?.status === "NEW"
                          ).length > 0 ? (
                            notifications
                              .filter(
                                (notification) =>
                                  notification.user[0]?.status === "NEW"
                              )
                              .slice(0, 3)
                              .map((notification) => {
                                const createdAt = parseISO(
                                  notification.createdAt
                                );
                                const formattedDate = format(
                                  createdAt,
                                  "EEEE, MMM. dd yyyy 'at' hh:mm a"
                                );
                                const timeAgo = formatDistanceToNow(createdAt, {
                                  addSuffix: true,
                                });

                                return (
                                  <Box
                                    key={notification._id}
                                    className={classes.notificationBox}
                                  >
                                    <Box>
                                      <Typography
                                        variant="h6"
                                        style={{
                                          width: "18vw",
                                          fontWeight: 500,
                                        }}
                                      >
                                        {notification.message}
                                      </Typography>
                                      <div className={classes.footer}>
                                        <Typography
                                          className={classes.caption}
                                          variant="caption"
                                        >
                                          {formattedDate}
                                        </Typography>
                                        <Typography
                                          className={classes.caption}
                                          variant="caption"
                                        >
                                          {timeAgo}
                                        </Typography>
                                      </div>
                                    </Box>

                                    <IconButton
                                      className={classes.closeIcon}
                                      onClick={() =>
                                        handleRead(notification._id)
                                      }
                                    >
                                      <CloseIcon
                                        style={{
                                          fontSize: "0.9rem",
                                          color: "black",
                                        }}
                                      />
                                    </IconButton>
                                  </Box>
                                );
                              })
                          ) : (
                            <Box className={classes.noNotificationsContainer}>
                              <img
                                src={NoNotificationImg}
                                alt="No notifications"
                                className={classes.noNotificationsImage}
                              />
                              <Typography
                                className={classes.noNotificationsText}
                              >
                                No notifications
                              </Typography>
                            </Box>
                          )}
                        </div>

                        <Box>
                          <Divider style={{ color: "red" }} />
                        </Box>
                        <Box
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            marginTop: "20px",
                            marginBottom: "20px",
                          }}
                        >
                          <Typography
                            variant="h6"
                            onClick={handleNotificationClickOpen}
                            style={{
                              fontWeight: "500",
                              color: "#5E58E4",
                              cursor: "pointer",
                            }}
                          >
                            {notifications.length > 0 &&
                              "View all notifications"}
                          </Typography>
                        </Box>
                      </Popover>
                    </div>
                  </ClickAwayListener>

                  <PopupState
                    variant="popover"
                    popupId="popup-popover0"
                    className={classes.popover}
                  >
                    {(popupState) => (
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={handlePopoverOpen}
                      >
                        {token && (
                          <>
                            <Avatar
                              className={classes.avatar}
                              src={avatarSrc}
                              alt="profile"
                            />
                          </>
                        )}
                        <Popover
                          open={isPopoverOpen}
                          anchorEl={anchorEl}
                          onClose={handlePopoverClose}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "center",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "center",
                          }}
                        >
                          <div
                            className={classes.popoverContent}
                            style={{
                              minWidth: "250px",
                              padding: "10px",
                              borderRadius: "1rem",
                            }}
                          >
                            {/* User Email Section */}
                            <Box
                              onClick={handleNavigateToProfile}
                              sx={{
                                cursor: "pointer",
                                "&:hover": {
                                  backgroundColor: "#5E58E41A", // Hover background color
                                  transition: "background-color 0.3s ease",
                                },
                                borderRadius: "1rem", // Consistent border radius
                              }}
                            >
                              <Typography
                                style={{
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  fontFamily: "Inter, sans-serif",
                                  cursor: "pointer",
                                  padding: "10px 20px",
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                }}
                                title={userEmail} // Tooltip for full email
                              >
                                {userEmail || ""}
                              </Typography>
                            </Box>

                            {/* Divider */}
                            <Divider
                              style={{
                                margin: "10px 0",
                                backgroundColor: "#5E58E41A", // Divider color
                              }}
                            />

                            {/* Logout Section */}
                            <Box
                              // className={classes.iconAndName}
                              onClick={handleLogOut}
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: "10px",
                                padding: "10px 20px",
                                cursor: "pointer",
                                "&:hover": {
                                  backgroundColor: "#5E58E41A", // Hover background color
                                  transition: "background-color 0.3s ease",
                                },
                                borderRadius: "1rem", // Consistent border radius
                              }}
                            >
                              <img
                                src="/images/logout.png"
                                width={20}
                                alt="Logout"
                                style={{
                                  display: "inline-block",
                                  verticalAlign: "middle",
                                }}
                              />
                              <Typography
                                variant="body2"
                                style={{
                                  fontWeight: 500,
                                  fontFamily: "Inter, sans-serif",
                                }}
                              >
                                Logout
                              </Typography>
                            </Box>
                          </div>
                        </Popover>
                      </div>
                    )}
                  </PopupState>
                </Box>
              </Box>
            </>
          )}
        </Toolbar>
        <Drawer
          anchor="left"
          open={isMobile ? drawerOpen : false}
          onClose={handleDrawerToggle}
        >
          {drawerList}
        </Drawer>
      </Box>
      <SeekerLogout
        open={logoutDialogOpen}
        onClose={() => setLogoutDialogOpen(false)}
      />
    </>
  );
};

export default AfterLoginHeader;
