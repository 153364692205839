import React from "react";
import { Grid, CssBaseline, makeStyles, Box } from "@material-ui/core";
import Sidebar from "../component/sidebar/Sidebar";
import AfterLoginHeader from "../component/header/AfterLoginheader";

const useStyles = makeStyles((theme) => ({
  sidebar: {
    boxShadow: "5px 0px #0000000D",
    backgroundColor: "#FFFFFF",
  },
  content: {
    width: "100%",
    // padding: theme.spacing(2),
    backgroundColor: "#F5F7FC",
    //boxShadow: "0px 0px 8px 4px rgba(0, 0, 0, 0.06)",
    marginTop: "6px",
  },
  mainContainer: {
    display: "flex",
    flexDirection: "row",
    gap: theme.spacing(1),
    backgroundColor: "#F5F7FC",
    paddingTop: "15px",
    paddingLeft: "220px",
    paddingRight: "183px",
    paddingBottom: "50px",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "5%",
      paddingRight: "3%",
    },
  },
}));

function JobDetailsLayout({ children }) {
  const classes = useStyles();

  return (
    <>
      <CssBaseline />
      <Box>
        {" "}
        <AfterLoginHeader />
      </Box>

      <Box className={classes.mainContainer}>
        <Box className={classes.content}>{children}</Box>
      </Box>
    </>
  );
}

export default JobDetailsLayout;
