import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  Paper,
  FormControl,
  styled,
  makeStyles,
  CircularProgress,
  Link,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import OTPInput from "otp-input-react";

import Popup from "../../../../component/modals/LoginModal";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import RecruiterResetPassword from "./RecruiterResetPassword";
import { postDataHandler } from "../../../../config/service";
import toast from "react-hot-toast";

const OtpSchema = Yup.object().shape({
  otp: Yup.string(),
});
const useStyles = makeStyles((theme) => ({
  authContainer: {
    maxWidth: "500px",
    height: "auto",
    margin: "auto",
    display: "flex",
    flexDirection: "row",
    "@media(max-width:480px)": {
      height: "510px",
      width: "350px",
    },
    "@media(max-width:390px)": {
      height: "510px",
      width: "320px",
    },
    "@media(max-width:340px)": {
      height: "500px",
      width: "270px",
    },
  },
  formContainer: {
    // height: '370px',
    borderRadius: "25px",
    backgroundColor: "white",
    padding: "48px",

    "@media(max-width:430px)": {
      borderRadius: "20px",
      padding: theme.spacing(3),
    },
    position: "relative",
  },
  heading: {
    fontWeight: 700,
    color: "#262626",
    fontSize: 24,
    lineHeight: "29px",
    fontFamily: "Inter",
    "@media(max-width:1200px)": { fontSize: 22, lineHeight: "28px" },
    "@media(max-width:992px)": { fontSize: 18, lineHeight: "24px" },
    "@media(max-width:767px)": { fontSize: 16, lineHeight: "18px" },
  },
  errorText: { color: "#F83D3D" },
  timerText: { color: "#5E58E4" },
}));

const ChamgepassBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

const OtpBox = styled(Box)({
  borderRadius: "10px",
  "& .mainBox": { background: "#fff" },
  "& .buttonBox": { marginTop: "10px" },
  "& .textbox": { padding: "3px 0px 20px", marginTop: "10px" },
});

const OtpFormControl = styled(FormControl)(({ hasError }) => ({
  display: "flex",
  justifyContent: "center",
  gap: "0px",
  "& input": {
    color: "#1C1C1C",
    width: "48px !important",
    height: "52px !important",
    margin: "0px 2.5px 0px 0.5px !important",
    borderRadius: "10px",
    background: "transparent",
    border: hasError ? "1px solid red" : "1px solid #AFB0B6",
    "&:focus": { border: "1px solid #5E58E4 !important" },
    "@media(max-width:480px)": {
      width: "37px !important",
      height: "37px !important",
    },
    "@media(max-width:390px)": {
      width: "31px !important",
      height: "31px !important",
    },
  },
}));

const RecruiterOtp = ({ open, onClose, handleResetClick }) => {
  const [timeLeft, setTimeLeft] = useState(180);
  const [timerActive, setTimerActive] = useState(true);
  const Email = sessionStorage.getItem("email");
  const [isOTPComplete, setIsOTPComplete] = useState(false);

  const [OTP, setOTP] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const history = useHistory();
  const classes = useStyles();
  const [isLoad, setIsLoad] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [openReset, setOpenReset] = React.useState(false);

  const handleOResetpen = () => {
    onClose();

    handleResetClick();
  };

  const handleResetClose = () => {
    setOpenReset(false);
    sessionStorage.removeItem("otpTimeLeft");
  };

  // Load remaining time from sessionStorage when the component mounts
  // useEffect(() => {
  //   const savedTime = sessionStorage.getItem("otpTimeLeft");
  //   if (savedTime) {
  //     const parsedTime = JSON.parse(savedTime);
  //     setTimeLeft(parsedTime);
  //     if (parsedTime > 0) {
  //       setTimerActive(true); // Start timer if there's time left
  //     }
  //   }
  // }, []);
  useEffect(() => {
    let intervalId;
    if (timerActive && timeLeft > 0) {
      intervalId = setInterval(() => {
        setTimeLeft((prevTime) => {
          if (prevTime === 1) {
            setTimerActive(false);
            clearInterval(intervalId); // Stop the timer
          }
          return prevTime - 1;
        });
      }, 1000);
    } else {
      clearInterval(intervalId);
    }
    return () => clearInterval(intervalId);
  }, [timerActive, timeLeft]);
  useEffect(() => {
    setTimeLeft(180); // Reset time to 3 minutes on open
    setTimerActive(true); // Enable timer
  }, [open]);

  // Format the time as MM:SS
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes < 10 ? `0${minutes}` : minutes}:${
      secs < 10 ? `0${secs}` : secs
    }`;
  };

  const handleResendOTP = async (setFieldValue) => {
    try {
      setIsLoad(true);
      const data = {
        email: Email,
      };
      const res = await postDataHandler("resendOTP", data);

      if (res?.responseCode === 200) {
        toast.success(res?.responseMessage);
        setTimerActive(true);
        setFieldValue("otp", "");
        setIsOTPComplete(false);
        setTimeLeft(180);
      } else {
        toast.error(res?.data?.responseMessage);
      }
    } catch (error) {
      toast.error(error);
    } finally {
      setIsLoad(false);
    }
  };

  const handleOtpSubmit = async (values, { setSubmitting }) => {
    const otpValue = values.otp;
    const data = {
      email: Email,
      otp: otpValue,
    };
    console.log(data);
    try {
      setIsLoading(true);
      const res = await postDataHandler("verifyOTP", data);

      if (res.responseCode == 200) {
        toast.success(res?.responseMessage);
        handleOResetpen();
        setIsLoading(false);
      } else {
        setErrorMessage(res?.data?.message);
        toast.error(res?.data?.responseMessage);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Popup open={open} handleClose={onClose}>
        <Box className={classes.authContainer}>
          <Box className={classes.formContainer}>
            <ChamgepassBox>
              <OtpBox>
                <Paper>
                  <Typography className={classes.heading}>
                    OTP Verification
                  </Typography>
                  <Box className="textbox">
                    <Typography variant="h6" style={{ lineHeight: "20px" }}>
                      {`Please enter the 6-digit verification code that was sent to ${Email}. The code is valid for 3 minutes.`}
                    </Typography>
                  </Box>

                  {/* Formik integration */}
                  <Formik
                    initialValues={{ otp: "" }}
                    validationSchema={OtpSchema}
                    onSubmit={handleOtpSubmit}
                  >
                    {({ errors, touched, setFieldValue, values }) => (
                      <Form>
                        <OtpFormControl
                          hasError={!!(errors.otp && touched.otp)}
                        >
                          <Field name="otp">
                            {({ field }) => (
                              <OTPInput
                                {...field}
                                value={field.value}
                                onChange={(otp) => {
                                  setFieldValue("otp", otp);
                                  setIsOTPComplete(otp.length === 6); // Check if OTP is complete
                                }}
                                OTPLength={6}
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  gap: "5px",
                                }}
                                otpType="number"
                                secure
                                autoFocus
                              />
                            )}
                          </Field>

                          <Box mt={1}>
                            {errors.otp && touched.otp && (
                              <Typography
                                variant="body2"
                                className={classes.errorText}
                              >
                                {errors.otp}
                              </Typography>
                            )}
                          </Box>
                        </OtpFormControl>
                        <Box
                          display="flex"
                          justifyContent="flex-end"
                          alignItems="center"
                          mt={0}
                        >
                          {timerActive ? (
                            <div
                              className="timer-container"
                              style={{ textAlign: "end", color: "red" }}
                            >
                              {formatTime(timeLeft)}
                            </div>
                          ) : (
                            <Link
                              onClick={() => handleResendOTP(setFieldValue)}
                              style={{ cursor: "pointer", color: "#5E58E4" }}
                            >
                              {isLoad ? (
                                <CircularProgress size={24} color="inherit" />
                              ) : (
                                "Resend OTP"
                              )}
                            </Link>
                          )}
                        </Box>

                        <Box style={{ marginTop: "25px" }}>
                          <Button
                            className={classes.btn}
                            variant="contained"
                            color="secondary"
                            fullWidth
                            type="submit"
                            disabled={isLoading || !isOTPComplete} // Disable button if OTP is not complete
                            //onClick={handleOtpOpen}
                          >
                            Submit
                            {isLoading && (
                              <CircularProgress
                                size={20}
                                style={{ color: "#fff", marginLeft: "10px" }}
                              />
                            )}
                          </Button>
                        </Box>
                      </Form>
                    )}
                  </Formik>
                </Paper>
              </OtpBox>
            </ChamgepassBox>
          </Box>
        </Box>
      </Popup>
      <RecruiterResetPassword open={openReset} onClose={handleResetClose} />
    </>
  );
};

export default RecruiterOtp;
