import React, { useContext, useState } from 'react';
import {
    Toolbar,
    IconButton,
    Typography,
    Drawer,
    List,
    ListItem,
    ListItemText,
    Box,
    Button,
    makeStyles,
} from "@material-ui/core";
import MenuIcon from '@material-ui/icons/Menu';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useHistory } from "react-router-dom";
import MegaMenu from '../MegaMenu';
import Logo from "../../assets/logoipsum.svg";
import RecruiterLogin from '../../views/pages/recruiter/recruiterAuth/RecruiterLogin';
import RecruiterForgot from '../../views/pages/recruiter/recruiterAuth/RecruiterForgot';
import Otp from '../../views/pages/recruiter/recruiterAuth/RecruiterOtp';
import { RecruiterAuthContext } from "../../context/recruiterAuth";
import RecruiterResetPassword from '../../views/pages/recruiter/recruiterAuth/RecruiterResetPassword';
import RecruiterOtp from '../../views/pages/recruiter/recruiterAuth/RecruiterOtp';
import toast from 'react-hot-toast';
const useStyles = makeStyles((theme) => ({
    menuItems: {
        cursor: "pointer",
        display: "flex",
        justifyContent: "flex-end",
        gap: "32px",
        alignItems: "center",
        position: 'relative',
        '@media (max-width:1024px)': {
            gap: "10px",
        },
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            alignItems: 'center',
            gap: '15px',
        },
    },
    textStyle: {
        color: "#000000CC",
        fontWeight: '400',
        lineHeight: '21px'
    },
    menuBtn: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: "18px",
        fontWeight: "500",
        '@media (max-width:1024px)': {
            fontWeight: "600",
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            marginTop: '16px',
        },
    },
    drawerList: {
        width: '100%',
        height: '100%',
    },
    drawerListItem: {
        padding: '16px 24px',
        borderBottom: `1px solid ${theme.palette.divider}`,
        '&:hover': {
            backgroundColor: theme.palette.action.hover,
        },
    },
    drawerListItemText: {
        fontSize: '18px',
        fontWeight: '400',
        color: "#000000CC",
    },
    navbar: {

        padding: '20px 0px 20px 0px',
        paddingLeft: '13%',
        paddingRight: '13%',
        boxShadow: "0px 4px 4px 0px #0000000D",
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            paddingLeft: '10px',
            paddingRight: '10px',
            flexDirection: 'column',
            alignItems: 'center',
        },
    },
    toolbar: {
        paddingLeft: '0px',
        paddingRight: '0px',
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    iconColor: {
        color: '#000000CC',
        opacity: "0.9",
    },
    logo: {
        maxWidth: '200px',

        display: 'block',
        cursor: 'pointer',
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
        [theme.breakpoints.up('md')]: {

        },
    },
}));

const BeforeLoginHeader = () => {
    const history = useHistory();
    const classes = useStyles();
    const { recruiterLoggedIn } = useContext(RecruiterAuthContext);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [isMegaMenuOpen, setIsMegaMenuOpen] = useState(false);
    const [open, setOpen] = React.useState(false);
    const [openForgot, setOpenForgot] = React.useState(false);
    const [openOtp, setOpenOtp] = React.useState(false);

    const { isRecruiterLoggedIn } = useContext(RecruiterAuthContext);

    const [openResetPassword, setOpenResetPassword] = React.useState(false);
    const [redirectPath, setRedirectPath]= useState();

    const handleOTPOpen = () => {
        setOpenOtp(true);
        setOpenForgot(false);
    };
    const handleResetOpen = () => {
        setOpenResetPassword(true);
        setOpenOtp(false);
        setOpenForgot(false);
    };

    const handleResetClose = () => setOpenResetPassword(false);

    const handleOpenLogin = () => {
        setOpen(true);
    };
    const handleDrawerToggle = () => {
        setDrawerOpen(!drawerOpen);
    };

    const toggleMegaMenu = () => {
        setIsMegaMenuOpen(!isMegaMenuOpen);
    };

    const handleClick = () => {
        history.push('/');
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => setOpen(false);

    const handleForgotOpen = () => {
        setOpenForgot(true);
        setOpen(false);
    };
    const handleOtpClose = () => {
        setOpenOtp(false);
        setOpenForgot(false);
    };
    const handleForgotClose = () => setOpenForgot(false);

    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));


    const handleClickPostJob = () => {
        setRedirectPath("/postNewJob")
        if (recruiterLoggedIn) {
            history.push("/postNewJob");
        } else {
          
            handleOpenLogin(); // Assuming this function opens a login modal or page
        }
    };


    const handleNavigation = (text) => {
        switch (text) {
            case 'Find Jobs':
                history.push('/job-search');
                break;
            case 'Post Jobs':
                
                handleClickPostJob();

                break;
            case 'Pricing':
                history.push("/pricing");
                
                break;
            case 'Contact Us':
                history.push("/contact-us");
                break;
            default:
                break;
        }
    };

    const handleLogoClick = () => {
        history.push('/recruiterlandingpage');
    };
    const handleOnClick = () => {
        history.push('/Companyregistration')
    }

    const drawerList = (
        <Box className={classes.drawerList} role="presentation" onClick={handleDrawerToggle} onKeyDown={handleDrawerToggle}>
            <List>
                {["Find Jobs", "Post Jobs", "Pricing", "Contact Us"].map((text) => (
                    <ListItem button className={classes.drawerListItem} key={text}>
                        <ListItemText onClick={() => handleNavigation(text)} primary={text} classes={{ primary: classes.drawerListItemText }} />
                    </ListItem>
                ))}
                <ListItem button className={classes.drawerListItem}>
                    <ListItemText primary="Sign Up" classes={{ primary: classes.drawerListItemText }} onClick={handleOnClick} />
                </ListItem>
                <ListItem button className={classes.drawerListItem}>
                    <ListItemText primary="Log In" classes={{ primary: classes.drawerListItemText }} onClick={handleOpen} />
                </ListItem>
            </List>
        </Box>
    );

    return (
        <>
            <Box className={classes.navbar}>
                <Toolbar className={classes.toolbar}>
                    {isMobile && (
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            onClick={handleDrawerToggle}
                        >
                            <MenuIcon />
                        </IconButton>
                    )}
                    <Box onClick={handleLogoClick} className={classes.logo}>
                        <img src={Logo} alt="logo" />
                    </Box>
                    {!isMobile && (
                        <Box className={classes.menuItems} flexGrow={1}>
                            {["Find Jobs", "Post Jobs", "Pricing", "Contact Us"].map((text) => (
                                <Box
                                    display="flex"
                                    alignItems="center"
                                    key={text}
                                    onClick={() => handleNavigation(text)}
                                    sx={{ cursor: 'pointer' }}
                                >
                                    <Typography
                                       variant="h4"
                                       display="inline"
                                       style={{ color: "#000000CC" }}

                                    >
                                        {text}
                                    </Typography>
                                    {text === 'Browse Jobs' && (
                                        isMegaMenuOpen ? (
                                            <ExpandLessIcon className={classes.iconColor} />
                                        ) : (
                                            <ExpandMoreIcon className={classes.iconColor} />
                                        )
                                    )}
                                </Box>
                            ))}
                            <Button variant="contained" color="secondary" className={classes.menuBtn} onClick={handleOpen}>
                                Sign in/ Register
                            </Button>
                            <MegaMenu isOpen={isMegaMenuOpen} />
                        </Box>
                    )}
                </Toolbar>
                <Drawer anchor="left" open={isMobile ? drawerOpen : false} onClose={handleDrawerToggle}>
                    {drawerList}
                </Drawer>
            </Box>
            <RecruiterResetPassword open={openResetPassword} onClose={handleResetClose} />
            <RecruiterLogin open={open} onClose={handleClose} handleForgotClick={handleForgotOpen} redirectPath={redirectPath} setRedirectPath={setRedirectPath}  />
            <RecruiterForgot open={openForgot} onClose={handleForgotClose} handleOTPClick={handleOTPOpen} />
            <RecruiterOtp open={openOtp} onClose={handleOtpClose} handleResetClick={handleResetOpen} />
        </>
    );
};

export default BeforeLoginHeader;
